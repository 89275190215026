/* eslint-disable no-useless-escape */
import { ValidatorFn } from "./models/ValidatorFn";

const validateUrl: ValidatorFn = (url: string): boolean => {
  const genericUrlRegex =
    /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&/=]*)$/i;
  const whatsappApiRegex =
    /^https:\/\/api\.whatsapp\.com\/send\?(?:phone=\d+)(?:&[a-zA-Z0-9_]+=[^&]*)?$/i;

  return genericUrlRegex.test(url) || whatsappApiRegex.test(url);
};
export default validateUrl;
