import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoute, PublicRoute } from ".";
import ChatUsers from "../features/chats/components/ChatUsers.component";
import Conversation from "../features/chats/components/Conversation.component";
import Inbox, {
  countSelectedFilters,
} from "../features/chats/components/Inbox.component";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  LoginPage,
  // RegisterPage,
  RecoveryPage,
  ProfilePage,
  PasswordPage,
  PrivacyPage,
  AuthLayoutPage,
  // LoginTenantPage,
} from "../pages";
import { IUser, UserType } from "../slices/users.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import Button from "../shared/components/Buttons/Button.component";
import ToggleSelectHeader from "../features/chats/components/ToggleHeader/ToggleSelectHeader.component";
import {
  ChatStatus,
  // IChat,
  getSearchChats,
  selectHistoryContact,
  selectSearchChats,
  selectTotalSearchChats,
} from "../slices/chats.slice";
import ChatHistory from "../features/chats/components/ChatHistory.component";
import SearchbarFilters from "../shared/components/SearchbarFilters.component";
import {
  ExternalAppType,
  getSearchExternalApps,
} from "../slices/externalapps.slice";
import { getSettings } from "../slices/settings.slice";
import useLGPD from "../hooks/useLGPD";
import { IFiltersDropdownInstances } from "../shared/components/@types/searchBarFilter.types";
import { defaultFilters } from "../pages/Chat.page";
import { getNotificationsAmount } from "../shared/utils/getChatNotificationsByUser";

const WidgetAgentRoute = ({
  toggleOpenClose,
  widgetType,
  disable,
  setDisable,
}: {
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  toggleOpenClose: boolean;
  widgetType: UserType;
}) => {
  const dispatch = useAppDispatch();

  const { showCode } = useLGPD();

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showUsersList, setShowUsersList] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [toggleList, setToggleList] = useState<string>("mine");
  const [userToggleList, setUserToggleList] = useState<string>("users");

  // const [renderDataSearch, setRenderDataSearch] = useState<IChat[]>([]);
  const { user, isAuthenticated, isLoadingAuth, isWidgetEmbedded } =
    useAppSelector((state) => state.auth);

  const [searchFiltersSelected, setSearchFiltersSelected] =
    useState<IFiltersDropdownInstances>(
      user?.customfilters?.chat?.filters ?? defaultFilters
    );
  const [searchFiltersOptions, setSearchFiltersOptions] =
    useState<IFiltersDropdownInstances>(
      user?.customfilters?.chat?.filtersOptions ?? defaultFilters
    );

  const [showWidgetAlert, setShowWidgetAlert] = useState<boolean>(false);
  const [showNoTeamsAlert, setShowNoTeamsAlert] = useState<boolean>(false);
  const [showNewGroupChat, setShowNewGroupChat] = useState<boolean>(false);

  const [skipSearch, setSkipSearch] = useState<number>(0);

  const { recentChats, backlogChats, mineChats } = useAppSelector(
    (state) => state.chats
  );

  // useEffect(() => {
  //   //
  // }, [isLoadingAuth]);

  const clearStoragedFilters = ({ _instance }: { _instance?: string }) => {
    if (typeof _instance === "undefined") {
      document
        .querySelectorAll(".dropdown-filters .clear")
        .forEach((el: any) => el.click());
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      setSearchFiltersSelected(defaultFilters);
      setSearchFiltersOptions(defaultFilters);
      dispatch(selectSearchChats([]));
      dispatch(selectTotalSearchChats(0));
      // setRenderDataSearch([]);
    } else {
      const _defaultFilters: any = defaultFilters;
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      setSearchFiltersSelected({
        ...searchFiltersSelected,
        [_instance]: _defaultFilters[_instance],
        // groupchat: [],
        // campaignchat: undefined,
      });
      setSearchFiltersOptions({
        ...searchFiltersOptions,
        [_instance]: _defaultFilters[_instance],
        // users: defaultFilters.users,
        // groupchat: [],
        // campaignchat: false,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        await dispatch(getSettings({}));
        await dispatch(
          getSearchChats({
            skip: 0,
            limit: 30,
            filter: {
              name: "",
              status: [ChatStatus.WAIT],
              grouptype: "backlog",
            },
            deleted: false,
            code: showCode,
            userId: user?._id,
          })
        );
        await dispatch(
          getSearchExternalApps({
            filter: {
              type: ExternalAppType.AGIDESK,
              "fields.validate": true,
              active: true,
              deleted: false,
            },
          })
        );
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    setShowFilter(false);
    setShowHistory(false);
    dispatch(selectHistoryContact(null));
    setToggleList("mine");
    // setToggleList(
    //   typeof searchFiltersSelected !== "undefined" &&
    //     countSelectedFilters({ searchFiltersSelected }) > 0
    //     ? "search"
    //     : "mine"
    // );
    setUserToggleList("users");
  }, [showUsersList]);

  if (isLoadingAuth) {
    return (
      <div
        className={`shadow-md bg-white top-0 bottom-0 right-0 h-full rounded-[3px] overflow-x-hidden fixed 
    ${
      !toggleOpenClose && widgetType === UserType.AGENT
        ? "hidden"
        : "flex flex-col w-full"
    }`}
      >
        <LoadingDots
          medium
          className="flex justify-center items-center flex-1"
        />
      </div>
    );
  }

  const errorScreen = () => {
    return (
      <AuthLayoutPage widgetType={widgetType}>
        <div className="flex flex-col items-center relative">
          <p className="text-[20px] font-semibold text-center">
            Ops! Algo inesperado aconteceu e perdemos a comunicação.
            <br />
            Tente novamente!
          </p>
          <Button
            minWidth
            extraClass="m-[25px]"
            label="Tentar novamente"
            onClick={() => null}
            icon="las la-arrow-right"
          />
        </div>
      </AuthLayoutPage>
    );
  };

  const getListComponent = () => {
    if (showHistory)
      return (
        <ChatHistory
          widgetType={widgetType}
          setShowFilter={setShowFilter}
          setShow={setShowUsersList}
        />
      );
    if (showUsersList)
      return (
        <ChatUsers
          showWidgetAlert={showWidgetAlert}
          setShowWidgetAlert={setShowWidgetAlert}
          showNoTeamsAlert={showNoTeamsAlert}
          setShowNoTeamsAlert={setShowNoTeamsAlert}
          showNewGroupChat={showNewGroupChat}
          setShowNewGroupChat={setShowNewGroupChat}
          widgetType={widgetType}
          setShow={setShowUsersList}
          toggleList={userToggleList || "users"}
        />
      );
    if (showFilter)
      return (
        <SearchbarFilters
          searchInstanceName="chats"
          clearFilters={clearStoragedFilters}
          skipSearchResults={skipSearch}
          searchFiltersOptions={searchFiltersOptions}
          searchFiltersSelected={searchFiltersSelected}
          setSearchFiltersOptions={setSearchFiltersOptions}
          setSearchFiltersSelected={setSearchFiltersSelected}
          setShowFilter={setShowFilter}
          setToggleList={setToggleList}
        />
      );

    return (
      <>
        <Inbox
          clearFilters={clearStoragedFilters}
          searchFiltersSelected={searchFiltersSelected}
          searchFiltersOptions={searchFiltersOptions}
          widgetType={UserType.NONE}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setShow={setShowUsersList}
          toggleList={toggleList}
          setToggleList={setToggleList}
          skipSearch={skipSearch}
          setSkipSearch={setSkipSearch}
          setShowHistory={setShowHistory}
        />
        {widgetType === UserType.EMBEDDED || isWidgetEmbedded ? null : (
          <Button
            minWidth
            disabled={false}
            extraClass="absolute bottom-0 right-0 m-[16px] xl:w-1/4 w-1/3 min-w-fit"
            label="Nova conversa"
            onClick={() => setShowUsersList(true)}
            icon="las la-plus"
          />
        )}
      </>
    );
  };

  return (
    <div
      className={`shadow-md bg-white top-0 bottom-0 right-0 h-full ${
        recentChats.length > 0 ? "w-[450px]" : "w-[93%]"
      } rounded-[3px] ${
        widgetType === UserType.EMBEDDED ? "!w-[500px]" : "mr-[32px]"
      } overflow-x-hidden fixed 
        ${
          !toggleOpenClose && widgetType === UserType.AGENT
            ? "hidden"
            : "flex flex-col"
        }`}
    >
      {widgetType === UserType.AGENT || widgetType === UserType.EMBEDDED ? (
        <Routes>
          <Route element={<PublicRoute widgetType={widgetType} />}>
            {/* <Route
              path="/company"
              element={<LoginTenantPage widgetType={widgetType} />}
            /> */}
            <Route
              path="/login"
              element={<LoginPage widgetType={widgetType} />}
            />
            <Route
              path="/login-agidesk"
              element={<LoginPage widgetType={widgetType} />}
            />
            <Route
              path="/recovery"
              element={<RecoveryPage widgetType={widgetType} />}
            />
            <Route
              path="/password"
              element={<PasswordPage widgetType={widgetType} />}
            />
            <Route
              path="/privacy"
              element={<PrivacyPage widgetType={widgetType} />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/"
              element={
                isAuthenticated ? <Navigate to="/chat" /> : <LoginPage />
              }
            />
            <Route
              path="/chat"
              element={
                <>
                  {/* HEADER */}
                  {widgetType === UserType.EMBEDDED ||
                  isWidgetEmbedded ? null : (
                    <ToggleSelectHeader
                      // setShowNewGroupChat={setShowNewGroupChat}
                      widgetType={widgetType}
                      setShow={setShowUsersList}
                      showHistory={showHistory}
                      setShowHistory={setShowHistory}
                      showFilter={showFilter}
                      setShowFilter={setShowFilter}
                      setShowWidgetAlert={setShowWidgetAlert}
                      setShowNoTeamsAlert={setShowNoTeamsAlert}
                      type={showUsersList ? "contact-widget" : "inbox-widget"}
                      toggleList={
                        showUsersList
                          ? userToggleList || "users"
                          : toggleList || "mine"
                      }
                      setToggleList={
                        showUsersList ? setUserToggleList : setToggleList
                      }
                      notificationsBacklog={
                        !showUsersList
                          ? backlogChats.reduce((accumulator, chat) => {
                              if (
                                chat?.notifications?.length &&
                                getNotificationsAmount(chat, user as IUser) >
                                  0 &&
                                chat?.status &&
                                ![
                                  ChatStatus.CANCELED,
                                  ChatStatus.SUSPEND,
                                  ChatStatus.FINISH,
                                ].includes(chat.status)
                              ) {
                                return accumulator + 1;
                              }
                              return accumulator;
                            }, 0)
                          : 0
                      }
                      notificationsMe={
                        !showUsersList
                          ? mineChats.reduce((accumulator, chat) => {
                              if (
                                chat?.notifications?.length &&
                                getNotificationsAmount(chat, user as IUser) >
                                  0 &&
                                chat?.status &&
                                ![
                                  ChatStatus.CANCELED,
                                  ChatStatus.FINISH,
                                ].includes(chat.status)
                              ) {
                                return accumulator + 1;
                              }
                              return accumulator;
                            }, 0)
                          : 0
                      }
                      notificationsTeams={0}
                    />
                  )}
                  {/* CHATS/USERS LIST */}
                  {getListComponent()}
                </>
              }
            />
            <Route
              path="/settings/profile"
              element={
                <ProfilePage
                  disable={disable}
                  setDisable={setDisable}
                  widget
                  toggleSidebar={false}
                  icon="las la-user-friends"
                  title="Nova equipe"
                />
              }
            />
            <Route
              path="/chat/:_id"
              element={
                <Conversation
                  disable={disable}
                  setDisable={setDisable}
                  widgetType={widgetType}
                  toggleList={toggleList}
                  setToggleList={setToggleList}
                  setShowHistory={setShowHistory}
                />
              }
            />
          </Route>
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      ) : (
        errorScreen()
      )}
    </div>
  );
};

export default WidgetAgentRoute;
