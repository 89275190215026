import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  UserType,
  deleteUser,
  reselectAgent,
  selectUser,
  updateUser,
} from "../slices/users.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import { SidebarComponent } from "../shared/components/Sidebars";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import ConfirmationModal from "../shared/components/Modals/ConfirmationModal.component";
import { getSettings } from "../slices/settings.slice";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";
import PermissionGroupsDropdown from "../shared/components/Dropdowns/PermissionGroups.dropdown.component";
import { userdata } from "../slices/auth.slice";

const UserPage = ({
  icon,
  title,
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const { canAccess } = useAccessControl();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tenantID, user } = useAppSelector((state) => state.auth);
  const { isLoadingUsers, selectedUser } = useAppSelector(
    (state) => state.users
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [showDefaultagentModal, setShowDefaultagentModal] =
    useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      await dispatch(selectUser({ _id: getId() }));
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  const getTenant = () => {
    if (
      typeof selectedUser?.tenant !== "undefined" &&
      selectedUser?.tenant !== null
    ) {
      return selectedUser?.tenant;
    }
    if (
      typeof tenantID !== "undefined" &&
      tenantID !== null &&
      tenantID.length > 0
    ) {
      return tenantID;
    }
    if (
      typeof user?.tenant !== "undefined" &&
      user?.tenant !== null &&
      user?.tenant.length > 0
    ) {
      return user?.tenant;
    }
    return undefined;
  };

  const goBack = () => {
    navigate(-1);
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedUser?._id) {
      const { payload } = await dispatch(deleteUser([selectedUser._id]));
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate(-1);
      } else {
        // SHOW MODAL
        setShowDefaultagentModal(true);
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _user = {
      ...selectedUser,
      avatar: selectedUser?.avatar?._id,
      teams: selectedUser?.teams?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      deleted: false,
      active: true,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateUser(_user));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSave = async () => {
    const _tenant = getTenant();
    if (_tenant === undefined || typeof _tenant === "undefined") return;
    setDisable(true);
    const _user = {
      ...selectedUser,
      tenant: _tenant,
      avatar: selectedUser?.avatar?._id,
      _id: selectedUser?._id,
      name: selectedUser?.name,
      roles: selectedUser?.roles,
      active: selectedUser?.active,
      phone: selectedUser?.phone,
      teams: selectedUser?.teams?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
    };
    const { payload } = await dispatch(updateUser(_user));
    if (user?._id === selectedUser?._id) {
      await dispatch(userdata());
    }
    setDisable(false);
    if (
      payload !== null &&
      typeof payload !== "undefined" &&
      typeof payload?.message !== "string"
    ) {
      dispatch(
        reselectAgent({
          ...selectedUser,
          ...payload
        })
      );
    } else {
      // SHOW MODAL
      setShowDefaultagentModal(true);
    }
  };

  if (isLoadingUsers)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        content={
          <CreateModal
            title={title}
            icon={icon}
            setShowModal={setShowModal}
            setVisible={setVisible}
          />
        }
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedUser?.created_at}
          updated={selectedUser?.updated_at}
          deleted={selectedUser?.deleted_at}
          title={selectedUser?.name || "-"}
          imported={!!selectedUser?.externalcode}
          icon="las la-user"
        />
        <Section
          icon="las la-info-circle"
          title="Informações"
          description="Edite as informações exibidas"
          component={
            <SectionElement instance={selectedUser} type="user-info" />
          }
        />
        {canAccess(Permissions.UPDATE_PERMISSIONS_GROUPS) && (
          <>
            <DividerInfoPages />
            <Section
              icon="las la-user-shield"
              title="Grupos de permissão do agente"
              description="Relacione os grupos de permissão"
              component={
                <PermissionGroupsDropdown
                  defaultValue={selectedUser?.permissiongroups}
                  onChange={(e, { value }) => {
                    if (value) {
                      const permissiongroups = value as string[];
                      dispatch(
                        reselectAgent({
                          ...selectedUser,
                          permissiongroups
                        })
                      );
                    }
                  }}
                />
              }
            />
          </>
        )}
        <DividerInfoPages />
        <Section
          icon="las la-user-friends"
          title="Equipes vinculadas ao agente"
          description="Relacione as equipes desejadas"
          component={
            <SectionElement
              setVisible={setVisible}
              instance={selectedUser}
              type="user-teams"
              setShowModal={setShowModal}
            />
          }
        />
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            selectedUser?.deleted ||
            selectedUser?.roles?.length === 0 ||
            typeof selectedUser?.name === "undefined" ||
            selectedUser?.name?.length < 2
          }
          deleted={selectedUser?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedUser?.deleted
              ? "Deseja reativar este agente?"
              : "Deseja remover este agente?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={canAccess(Permissions.DELETE_AGENTS) ? goReactive : undefined}
          remove={canAccess(Permissions.DELETE_AGENTS) ? goRemove : undefined}
          save={canAccess(Permissions.UPDATE_AGENTS) ? goSave : undefined}
        />
        {/* MODALS */}
        {showModal ? (
          <RelationModal
            title="Relacionar equipes"
            icon={icon}
            setShowModal={setShowModal}
          />
        ) : null}
        {showDefaultagentModal ? (
          <ConfirmationModal
            widgetType={UserType.NONE}
            disabled={false}
            title="Ação interrompida"
            content={`O agente <b>${selectedUser?.name}</b> está selecionado como usuário padrão para ações anônimas e, assim, não pode ser excluído ou inativado.<br />
            Para alterar o usuário padrão, acesse <b>Configurações > Configurações gerais > Usuário padrão.</b>`}
            setShowModal={setShowDefaultagentModal}
            action={() => {
              setShowDefaultagentModal(false);
              navigate("/settings/general#settings-defaultagent");
            }}
            submitIcon="las la-cog"
            submitLabel="Ir para configurações"
          />
        ) : null}
      </Sidebar.Pusher>
    </div>
  );
};

export default UserPage;
