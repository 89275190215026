/* eslint-disable no-fallthrough */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/require-default-props */
import React from "react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import WhatsAppMobileHeaderPreview from "./WhatsAppMobileHeaderPreview.component";
import { HeaderCardItemType } from "./HeaderCardGroup.component";
import ActionLink from "./ActionLink.component";
import { useAppSelector } from "../../../hooks/redux/hooks";
import { useFormatTemplateText } from "../../../hooks/useFormatText";
import { IFile } from "../../models/interfaces/file.interface";

export type WhatsAppMobilePreviewBodyType = HeaderCardItemType | "auth";

export interface WhatsAppMobilePreviewProps {
  type?: WhatsAppMobilePreviewBodyType;
  buttons?: boolean;
}

const WhatsAppMobilePreview = ({
  type = "none",
  buttons = false,
}: WhatsAppMobilePreviewProps) => {
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);
  const URL = process.env.REACT_APP_API_BASE_URL;

  const getTypeContent = () => {
    const dateNow = new Date();
    const formattedHour = format(dateNow, "hh:mm a", { locale: ptBR });
    switch (type) {
      case "none":
      case "text":
      case "auth":
        return (
          <div className="flex flex-col items-start gap-4 self-stretch w-full">
            {type === "text" && (
              <span className="leading-trim-both text-edge-cap text-150 leading-6 font-bold w-full word-wrap-2">
                {selectedLocalTemplate?.selectedHeader?.text ||
                  "Cabeçalho do template"}
              </span>
            )}
            <span
              className="leading-trim-both text-edge-cap text-100 leading-5 fg-weak w-full break-words"
              dangerouslySetInnerHTML={{
                __html: useFormatTemplateText(
                  selectedLocalTemplate?.selectedContent?.contentExample ||
                    "Este é um exemplo de como vai ficar o seu template"
                ),
              }}
            />
            <span className="leading-trim-both fg-weaker text-edge-cap text-25 leading-4 italic w-full break-words">
              {selectedLocalTemplate?.selectedFooter}
            </span>
            <p className="w-full text-end leading-trim-both text-edge-cap text-10 leading-5 fg-weak">
              {formattedHour}
            </p>
          </div>
        );
      case "video":
      case "image":
      // case "document":
      default:
        return (
          <>
            <div
              className={`flex h-28 py-4 ${
                type === "image" ? "px-[54px]" : ""
              } justify-center items-center gap-2 self-stretch rounded-1 ${
                !selectedLocalTemplate?.selectedHeader?.image &&
                !selectedLocalTemplate?.selectedHeader?.video &&
                !selectedLocalTemplate?.selectedHeader?.document
                  ? "bg-slate-300"
                  : "bg-default"
              }`}
            >
              {!selectedLocalTemplate?.selectedHeader?.image &&
                !selectedLocalTemplate?.selectedHeader?.video &&
                !selectedLocalTemplate?.selectedHeader?.document && (
                  <i className={`las la-${type} text-500 fg-weak`} />
                )}
              {type === "image" &&
                selectedLocalTemplate?.selectedHeader?.image && (
                  <img
                    alt="templateImage"
                    src={
                      (selectedLocalTemplate?.selectedHeader?.image as any)
                        ?.preview ||
                      `${URL}/api/files/${
                        (selectedLocalTemplate?.selectedHeader?.image as IFile)
                          ?.path
                      }/${
                        (selectedLocalTemplate?.selectedHeader?.image as IFile)
                          ?.name
                      }`
                    }
                  />
                )}
              {type === "video" &&
                selectedLocalTemplate?.selectedHeader?.video && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video
                    controls
                    className="fit-content flex-justify-center"
                    id="templateVideo"
                    src={
                      (selectedLocalTemplate?.selectedHeader?.video as any)
                        ?.preview
                    }
                  />
                )}
              {/* {type === "document" &&
                selectedLocalTemplate?.selectedHeader?.document && (
                  <div className="flex flex-col w-full items-center justify-center text-center leading-trim-both text-edge-cap text-25 leading-5 fg-weak mx-auto my-4">
                    <i className="las la-file-download text-[40px]" />
                    <a
                      id="headerDocument"
                      href={
                        selectedLocalTemplate?.selectedHeader
                          ?.document as string
                      }
                      target="_blank"
                      type="application/octet-stream"
                      download="arquivo-template"
                      rel="noreferrer"
                    >
                      Clique para fazer o download do arquivo
                    </a>
                  </div>
                )} */}
            </div>
            <span
              className="leading-trim-both text-edge-cap text-100 leading-5 fg-weak w-full break-words"
              dangerouslySetInnerHTML={{
                __html: useFormatTemplateText(
                  selectedLocalTemplate?.selectedContent?.contentExample ||
                    `Este é um exemplo de como vai ficar o seu template com header de ${
                      type === "image" ? "imagem" : "vídeo"
                      // : type === "document"
                      // ? "documento"
                    }.`
                ),
              }}
            />
            <span className="leading-trim-both fg-weaker text-edge-cap text-25 leading-4 italic w-full break-words">
              {selectedLocalTemplate?.selectedFooter}
            </span>
            <p className="w-full text-end leading-trim-both text-edge-cap text-10 leading-5 fg-weak">
              {formattedHour}
            </p>
          </>
        );
      // case "auth":
      //   return (
      //     <div className="flex flex-col gap-2">
      //       <span className="leading-trim-both text-edge-cap text-100 leading-5 fg-default">
      //         <b>288169</b> é o seu código de verificação. Para sua segurança,
      //         não compartilhe com ninguém este número.
      //       </span>
      //       <p className="w-full text-end leading-trim-both text-edge-cap text-10 leading-5 fg-weak">
      //         {formattedHour}
      //       </p>
      //     </div>
      //   );
      // return (
      //   <p className="w-full text-end leading-trim-both text-edge-cap text-10 leading-5 fg-weak">
      //     {formattedHour}
      //   </p>
      // );
    }
  };

  const getShowButtons = () => {
    // if (type === "auth") {
    //   return (
    //     <div className="h-fit w-full flex flex-col p-2.5 gap-3 rounded-1 bg-default items-center justify-center">
    //       <ActionLink
    //         size="medium"
    //         label="Preencher"
    //         state="default"
    //         variant="link"
    //         leadingIcon="las la-edit"
    //       />
    //     </div>
    //   );
    // }
    const _buttons: JSX.Element[] = [];
    if (!selectedLocalTemplate?.selectedButtons) return null;
    for (const _button of selectedLocalTemplate.selectedButtons) {
      if (_button?.text && _button?.type) {
        _buttons.push(
          <div
            key={_button.text}
            className="h-fit w-full flex flex-col p-2.5 gap-3 rounded-1 bg-default items-center justify-center"
          >
            <ActionLink
              size="medium"
              label={_button.text}
              state="default"
              variant="link"
              leadingIcon={
                _button.type === "PHONE_NUMBER"
                  ? "las la-phone"
                  : "las la-external-link-alt"
                // : _button.type === "URL"
                // ? "las la-at"
              }
            />
          </div>
        );
      }
    }
    return _buttons;
  };

  return (
    <div id="WhatsAppMobilePreview">
      <div id="island" />
      <WhatsAppMobileHeaderPreview />
      <div id="WhatsAppBody" className="flex flex-col items-center">
        <div
          id="WhatsAppBodyContent"
          className="h-fit w-full flex flex-col p-2.5 items-start gap-3 self-stretch rounded-1 bg-default"
        >
          {getTypeContent()}
        </div>
        {buttons && getShowButtons()}
      </div>
    </div>
  );
};

export default WhatsAppMobilePreview;
