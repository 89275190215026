import React, { useMemo } from "react";
import DropdownComponent from "../Dropdown.component";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";

const TemplateStatusDropdown = ({
  status,
  setStatus,
}: {
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const options: ddOptions[] = useMemo(
    () => [
      {
        key: "active",
        value: "active",
        text: "Ativo",
      },
      {
        key: "inactive",
        value: "inactive",
        text: "Inativo",
      },
      {
        key: "update",
        value: "update",
        text: "Atualizar",
      },
      {
        key: "rejected",
        value: "rejected",
        text: "Rejeitado",
      },
      {
        key: "draft",
        value: "draft",
        text: "Rascunho",
      },
      {
        key: "active-pending",
        value: "active-pending",
        text: "Ativo - Qualidade pendente",
      },
      {
        key: "active-high",
        value: "active-high",
        text: "Ativo - Qualidade alta",
      },
      {
        key: "active-medium",
        value: "active-medium",
        text: "Ativo - Qualidade média",
      },
      {
        key: "active-low",
        value: "active-low",
        text: "Ativo - Qualidade baixa",
      },
      {
        key: "paused",
        value: "paused",
        text: "Pausado",
      },
      {
        key: "in-review",
        value: "in-review",
        text: "Em análise",
      },
      {
        key: "disabled",
        value: "disabled",
        text: "Desabilitado",
      },
      {
        key: "appeal-requested",
        value: "appeal-requested",
        text: "Em apelação",
      },
    ],
    []
  );

  return (
    <DropdownComponent
      id="dropdown-integrations"
      fluid
      required
      search
      selection
      placeholder="Situações"
      clearable
      onChange={(e, { value }) => {
        setStatus(value as string);
      }}
      options={options}
      className={`${status ? "selected" : ""}`}
      value={status}
    />
  );
};

export default TemplateStatusDropdown;
