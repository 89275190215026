const removeDuplicates = (arr: any, key?: string) => {
  if (typeof arr === "undefined" || arr.length === 0 || !Array.isArray(arr))
    return arr;
  if (typeof key === "undefined") {
    return arr.filter(
      (v: any, i: any, a: any) => a.findIndex((v2: any) => v2 === v) === i
    );
  }
  return arr.filter(
    (v: any, i: any, a: any) =>
      a.findIndex((v2: any) => v2[key] === v[key]) === i
  );
};

export default removeDuplicates;
