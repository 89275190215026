/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useCallback } from "react";
import { Form } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import ButtonTypeDropdown from "./ButtonType.dropdown.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { reselectLocalTemplate } from "../../../slices/templates.slice";
import customToast from "../../utils/customToast";
import { IToastType } from "../../models/types/Toast.type";
import PhoneFlagInput from "../PhoneFlagInput.component";
import { validNumberLength } from "../../utils/handleMaskLength";
import validateUrl from "../../utils/validation/url";

export type ButtonFormState = "empty" | "QUICK_REPLY" | "PHONE_NUMBER" | "URL";

export interface ButtonFormItem {
  type: ButtonFormState;
  text: string;
  phone?: string;
  example?: any;
  phone_number?: string;
  url?: string;
}

const ButtonFormGroup = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);

  const getStartButtons = useCallback(() => {
    if (selectedLocalTemplate?.selectedButtons?.length) {
      return selectedLocalTemplate?.selectedButtons;
    }
    if (selectedLocalTemplate?.selectedCategory === "auth") {
      dispatch(
        reselectLocalTemplate({
          ...selectedLocalTemplate,
          selectedButtons: [
            {
              type: "QUICK_REPLY",
              text: "Copiar código",
            },
          ],
        })
      );
      return [
        {
          type: "QUICK_REPLY",
          text: "Copiar código",
        },
      ];
    }
    return [];
  }, []);

  const [buttons, setButtons] = useState<ButtonFormItem[]>([]);

  useEffect(() => {
    setButtons(getStartButtons() as ButtonFormItem[]);
  }, [getStartButtons]);

  const updateButtons = (newButtons: ButtonFormItem[]) => {
    setButtons(newButtons);
    dispatch(
      reselectLocalTemplate({
        ...selectedLocalTemplate,
        selectedButtons: newButtons,
      })
    );
  };

  const validateButtonRules = (newButtons: ButtonFormItem[]): boolean => {
    const quickReplyCount = newButtons.filter(
      (button) => button.type === "QUICK_REPLY"
    ).length;
    const phoneCount = newButtons.filter(
      (button) => button.type === "PHONE_NUMBER"
    ).length;
    const urlCount = newButtons.filter(
      (button) => button.type === "URL"
    ).length;

    if (quickReplyCount > 3) {
      customToast({
        type: IToastType.ERROR,
        message: "Você pode usar no máximo 3 botões de Resposta Rápida.",
      });
      return false;
    }

    if (phoneCount > 1) {
      customToast({
        type: IToastType.ERROR,
        message: "Você pode usar no máximo 1 botão de Telefone.",
      });
      return false;
    }

    if (urlCount > 1) {
      customToast({
        type: IToastType.ERROR,
        message: "Você pode usar no máximo 1 botão de URL.",
      });
      return false;
    }

    if (quickReplyCount > 0 && (phoneCount > 0 || urlCount > 0)) {
      customToast({
        type: IToastType.ERROR,
        message:
          "Botões de Resposta Rápida não podem ser combinados com botões de Telefone ou URL.",
      });
      return false;
    }

    return true;
  };

  const removeButtonGroup = (index: number) => {
    const newButtons = buttons.filter((_, i) => i !== index);
    if (validateButtonRules(newButtons)) {
      updateButtons(newButtons);
    }
  };

  const [error, setError] = useState<boolean>(false);

  const handleTypeChange = (index: number, type: ButtonFormState) => {
    const newButtons = buttons.map((button, i) =>
      i === index ? { ...button, type } : button
    );
    if (validateButtonRules(newButtons)) {
      updateButtons(newButtons);
    }
  };

  const handleTextChange = (
    index: number,
    value: string,
    field: "text" | "phone" | "url"
  ) => {
    const newButtons = buttons.map((button, i) =>
      i === index ? { ...button, [field]: value } : button
    );
    if (validateButtonRules(newButtons)) {
      updateButtons(newButtons);
    }
  };

  const addButtonGroup = () => {
    const newButton: ButtonFormItem = {
      type: "empty",
      text: "",
    };
    const newButtons = [...buttons, newButton];
    if (validateButtonRules(newButtons)) {
      updateButtons(newButtons);
    }
  };

  return (
    <div
      id="ButtonFormGroup"
      className="flex flex-col relative w-full items-start gap-4"
    >
      {buttons
        .filter((_b) =>
          ["empty", "URL", "PHONE_NUMBER", "QUICK_REPLY"].includes(_b.type)
        )
        .map((button, index) => (
          <Form key={index} className="flex p-5 w-full rounded-2 bg-secondary">
            {button.type === "empty" ? (
              <Form.Group className="!m-0 gap-3 flex items-end">
                <ButtonTypeDropdown
                  onChange={(e, { value }) => {
                    handleTypeChange(index, value as ButtonFormState);
                  }}
                />
                {selectedLocalTemplate?.selectedCategory !== "auth" && (
                  <i
                    role="presentation"
                    className="w-[34px] h-[38px] gap-3 py-2 px-4 flex items-center justify-center rounded-2 las la-trash fg-subtle text-200"
                    onClick={() => removeButtonGroup(index)}
                  />
                )}
              </Form.Group>
            ) : (
              <Form.Group className="!m-0 gap-3 flex items-end">
                <ButtonTypeDropdown
                  fluid
                  disabled={selectedLocalTemplate?.selectedCategory === "auth"}
                  value={button.type}
                  onChange={(e, { value }) => {
                    handleTypeChange(index, value as ButtonFormState);
                  }}
                />
                <Form.Input
                  label="Texto"
                  value={button.text}
                  maxLength={20}
                  onChange={(e, { value }) => {
                    handleTextChange(index, value, "text");
                  }}
                  variant="outlined"
                  placeholder="Digite um texto"
                />
                {button.type === "PHONE_NUMBER" && button?.text?.length > 0 && (
                  <PhoneFlagInput
                    readOnly={button?.text?.length === 0}
                    error={
                      !button?.phone &&
                      button?.phone?.length === 0 &&
                      !button?.phone_number &&
                      button?.phone_number?.length === 0
                        ? false
                        : !validNumberLength({
                            value: button?.phone || button?.phone_number || "",
                          })
                    }
                    defaultValue={button?.phone || button?.phone_number || ""}
                    onChange={(value: string) => {
                      handleTextChange(index, value, "phone");
                    }}
                  />
                )}
                {button.type === "URL" && button?.text?.length > 0 && (
                  <Form.Input
                    label="URL do site"
                    maxLength={150}
                    value={button?.example?.[0] || button.url}
                    type="text"
                    onChange={(e, { value }) => {
                      const isValid = validateUrl(value);
                      setError(!isValid);
                      handleTextChange(index, value, "url");
                    }}
                    variant="outlined"
                    placeholder="Insira a URL"
                    error={error} // Adiciona o estado de erro ao componente
                    {...(error && {
                      // Exibe mensagem de erro customizada
                      "data-tooltip": "Insira uma URL válida",
                      "data-position": "top right",
                      "data-variation": "error",
                    })}
                  />
                )}
                {selectedLocalTemplate?.selectedCategory !== "auth" && (
                  <i
                    role="presentation"
                    className="w-[34px] h-[38px] gap-3 py-2 px-4 flex items-center justify-center rounded-2 las la-trash fg-subtle text-200"
                    onClick={() => removeButtonGroup(index)}
                  />
                )}
              </Form.Group>
            )}
          </Form>
        ))}
      {selectedLocalTemplate?.selectedCategory !== "auth" &&
        !["/settings/template/agibot", "/settings/template/whatsapp"].includes(
          location.pathname
        ) &&
        buttons.length < 3 && (
          <button
            type="button"
            className="flex items-center border border-primary rounded-1 gap-1 py-0 px-3 min-h-[34px] font-bold bg-default fg-primary hover:text-base-white hover:bg-primary text-center leading-trim-both text-edge-cap text-50 leading-5"
            onClick={addButtonGroup}
          >
            <i className="las la-plus" />
            Adicionar novo botão
          </button>
        )}
    </div>
  );
};

export default ButtonFormGroup;
