import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import { userdata } from "../../../../slices/auth.slice";
import { UserType } from "../../../../slices/users.slice";
import customToast from "../../../../shared/utils/customToast";
import { IToastType } from "../../../../shared/models/types/Toast.type";
import { externalappsService, usersService } from "../../../../services";
import {
  ExternalAppType,
  IExternalAppBotmaker,
  IExternalAppWhatsApp,
  selectExternalappsBotmaker,
  selectExternalappsWhatsApp,
  selectTotalExternalappsBotmaker,
  selectTotalExternalappsWhatsApp,
} from "../../../../slices/externalapps.slice";
import useUserIsAdmin from "../../../../hooks/useUserIsAdmin";
import { selectTemplate } from "../../../../slices/messages.slice";
import { selectHistoryContact } from "../../../../slices/chats.slice";
import NotificationBadge from "../NotificationBadge.component";
import CompanyProfile from "../../../../shared/components/CompanyProfile.component";
import useAccessControl from "../../../../hooks/useAccessControl";
import { Permissions } from "../../../../slices/permissiongroups.slice";
import UserProfileStatus from "../../../../shared/components/UserProfileStatus/index.component";

interface IToggleSelectHeader {
  type: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShowWidgetAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowNoTeamsAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowNewGroupChat: React.Dispatch<React.SetStateAction<boolean>>;
  toggleList: string;
  setToggleList: React.Dispatch<React.SetStateAction<string>>;
  notificationsBacklog?: number;
  notificationsMe?: number;
  notificationsTeams?: number;
  widgetType: UserType | undefined;
  showHistory: boolean;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleSelectHeader = ({
  type,
  setShow,
  setShowWidgetAlert,
  setShowNoTeamsAlert,
  toggleList,
  setToggleList,
  notificationsBacklog,
  notificationsMe,
  notificationsTeams,
  widgetType,
  showHistory,
  setShowHistory,
  showFilter,
  setShowFilter,
}: IToggleSelectHeader) => {
  const { canAccess } = useAccessControl();
  const { isAdmin } = useUserIsAdmin();
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedHistoryContact } = useAppSelector((state) => state.chats);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [_activeIndex, setActiveIndex] = useState<number>(1);

  useEffect(() => {
    if (["inbox", "inbox-widget"].includes(type)) {
      if (toggleList === "backlog") {
        setActiveIndex(0);
      }
      if (toggleList === "mine") {
        setActiveIndex(1);
      }
      if (toggleList === "teams") {
        setActiveIndex(2);
      }
    } else {
      if (toggleList === "contacts") {
        setActiveIndex(0);
      }
      if (toggleList === "users") {
        setActiveIndex(1);
      }
    }
  }, [toggleList, type]);

  let panes = [
    {
      menuItem: {
        key: "contacts",
        icon: <i className="las la-address-book text-[16px] pr-1" />,
        content: <span>Contatos</span>,
      },
      render: () => null,
    },
    {
      menuItem: {
        key: "users",
        icon: <i className="las la-user text-[16px] pr-1" />,
        content: <span>Agentes</span>,
      },
      render: () => null,
    },
  ];

  if (["inbox", "inbox-widget"].includes(type)) {
    panes = [
      {
        menuItem: {
          key: "inbox",
          icon: <i className="las la-comment-dots text-[16px] pr-1" />,
          content: (
            <div className="flex gap-1">
              <span>Fila de espera</span>
              {notificationsBacklog && notificationsBacklog > 0 ? (
                <NotificationBadge badge={notificationsBacklog} />
              ) : null}
            </div>
          ),
        },
        render: () => null,
      },
      {
        menuItem: {
          key: "mine",
          icon: <i className="las la-inbox text-[16px] pr-1" />,
          content: (
            <div className="flex gap-1 items-center">
              <span>Minhas conversas</span>
              {notificationsMe && notificationsMe > 0 ? (
                <NotificationBadge badge={notificationsMe} />
              ) : null}
            </div>
          ),
        },
        render: () => null,
      },
      {
        menuItem: {
          key: "teams",
          icon: <i className="las la-users text-[16px] pr-1" />,
          content: (
            <div className="flex gap-1">
              <span>Minhas equipes</span>
              {notificationsTeams && notificationsTeams > 0 ? (
                <NotificationBadge badge={notificationsTeams} />
              ) : null}
            </div>
          ),
        },
        render: () => null,
      },
    ];
  }

  const onClickContacts = async () => {
    dispatch(selectTemplate({ template: null }));
    if (user?.teams && user.teams.length > 0) {
      const payload = await externalappsService.searchExternalAppsToChat({
        teams: user.teams?.map((_t) => {
          if (typeof _t === "string") return _t;
          return _t._id || "";
        }),
        types: [ExternalAppType.WHATSAPP, ExternalAppType.BOTMAKER],
      });

      if (!payload || payload.length === 0 || payload.statusCode === 401) {
        if (widgetType === UserType.NONE && isAdmin) {
          navigate("/settings/integrations");
          customToast({
            type: IToastType.WARNING,
            message: t("toast.warning.no-integration"),
          });
        } else if (
          (widgetType === UserType.NONE ||
            widgetType === UserType.AGENT ||
            widgetType === UserType.EMBEDDED) &&
          setShowWidgetAlert
        ) {
          setShowWidgetAlert(true);
        }
      } else {
        const _whatsapp: IExternalAppWhatsApp[] = [];
        const _bot: IExternalAppBotmaker[] = [];
        payload.forEach(
          (_extApp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
            if (_extApp.type === ExternalAppType.WHATSAPP) {
              _whatsapp.push(_extApp as IExternalAppWhatsApp);
            } else if (_extApp.type === ExternalAppType.BOTMAKER) {
              _bot.push(_extApp as IExternalAppBotmaker);
            }
          }
        );
        dispatch(selectExternalappsWhatsApp(_whatsapp));
        dispatch(selectTotalExternalappsWhatsApp(_whatsapp.length));
        dispatch(selectExternalappsBotmaker(_bot));
        dispatch(selectTotalExternalappsBotmaker(_bot.length));
        if (
          payload.length === 1 &&
          typeof payload[0]?._id !== "undefined" &&
          payload[0]._id
        ) {
          const _extapp = payload[0];
          if (
            _extapp.type === ExternalAppType.BOTMAKER &&
            _extapp.fields.externalphones.length === 1 &&
            typeof _extapp.fields.externalphones[0]?.phone !== "undefined" &&
            _extapp.fields.externalphones[0]?.phone.length > 0
          ) {
            await usersService.profile({
              externalapp: _extapp._id,
              defaulttemplate: "",
              externalappnumber: _extapp.fields.externalphones[0]?.phone,
            });
          } else {
            await usersService.profile({
              externalapp: _extapp._id,
              defaulttemplate: "",
              externalappnumber: user?.profile?.externalappnumber ?? "",
            });
          }
          await dispatch(userdata());
        }
        setToggleList("contacts");
      }
    } else if (widgetType === UserType.NONE && isAdmin) {
      navigate("/settings/teams");
      customToast({
        type: IToastType.WARNING,
        message: t("toast.warning.no-team"),
      });
      setActiveIndex(1);
      setToggleList("agents");
    } else if (
      (widgetType === UserType.NONE || widgetType === UserType.AGENT) &&
      setShowNoTeamsAlert
    ) {
      setShowNoTeamsAlert(true);
      customToast({
        type: IToastType.WARNING,
        message: t("toast.warning.no-team"),
      });
      setActiveIndex(1);
      setToggleList("agents");
    }
  };

  const getToggleComponent = () => {
    if (showHistory) {
      if (
        selectedHistoryContact !== null &&
        typeof selectedHistoryContact?.text !== "undefined" &&
        selectedHistoryContact?.text.length > 0
      ) {
        return (
          <div className="flex items-center justify-between gap-2">
            <div className="truncate flex items-center text-agitalks-gray-333 text-[14px] font-bold leading-[14px] gap-1">
              <i className="las la-history text-[16px]" />
              <span className="truncate">
                Histórico de {selectedHistoryContact.text}
              </span>
            </div>
            <div className="flex gap-1">
              <button
                type="button"
                className="inbox-button"
                onClick={() => {
                  dispatch(selectHistoryContact(null));
                }}
              >
                <i className="las la-eraser text-[16px]" />
                <span>Limpar</span>
              </button>
              <button
                type="button"
                className="inbox-button"
                onClick={() => {
                  dispatch(selectHistoryContact(null));
                  setShowHistory((prevState) => !prevState);
                  setShow(false);
                }}
              >
                <i className="las la-arrow-left text-[16px]" />
                <span>Voltar</span>
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className="flex items-center justify-between">
          <div className="truncate flex items-center text-agitalks-gray-333 text-[14px] font-bold leading-[14px] gap-1">
            <i className="las la-history text-[16px]" />
            <span className="truncate">Histórico</span>
          </div>
          <div className="flex">
            <button
              type="button"
              className="inbox-button"
              onClick={() => {
                setShowHistory((prevState) => !prevState);
                setShow(false);
              }}
            >
              <i className="las la-arrow-left text-[16px]" />
              <span>Voltar</span>
            </button>
          </div>
        </div>
      );
    }
    if (showFilter) {
      return (
        <div className="flex items-center justify-between">
          <div className="truncate flex items-center text-agitalks-gray-333 text-[14px] font-bold leading-[14px] gap-1">
            <i className="las la-filter text-[16px]" />
            <span className="truncate">Filtros</span>
          </div>
          <div className="flex">
            <button
              type="button"
              className="inbox-button"
              onClick={() => {
                setShowFilter((prevState) => !prevState);
                setShow(false);
              }}
            >
              <i className="las la-arrow-left text-[16px]" />
              <span>Voltar</span>
            </button>
          </div>
        </div>
      );
    }
    if (toggleList === "search") {
      return (
        <div className="flex items-center justify-between mt-1">
          <div className="truncate flex items-center text-agitalks-gray-333 text-[14px] font-bold leading-[14px] gap-1">
            <i className="las la-filter text-[16px]" />
            <span className="truncate">Resultados</span>
          </div>
          <div className="flex">
            <button
              type="button"
              className="inbox-button"
              onClick={() => {
                setShowFilter(false);
                setShow(false);
                setToggleList("mine");
              }}
            >
              <i className="las la-arrow-left text-[16px]" />
              <span>Voltar</span>
            </button>
          </div>
        </div>
      );
    }
    return (
      <Tab
        menu={{ secondary: true, pointing: true, widths: panes.length }}
        panes={panes}
        renderActiveOnly
        activeIndex={_activeIndex}
        onTabChange={async (e, { activeIndex }) => {
          const _toggleListChats: { [key: number]: string } = {
            0: "backlog",
            1: "mine",
            2: "teams",
          };
          const _toggleListUsers: { [key: number]: string } = {
            0: "contacts",
            1: "users",
          };

          if (typeof activeIndex !== "undefined" && +activeIndex > -1) {
            setActiveIndex(+activeIndex);
            if (["inbox", "inbox-widget"].includes(type)) {
              setToggleList(_toggleListChats[+activeIndex]);
            } else if (+activeIndex === 1) {
              setToggleList(_toggleListUsers[+activeIndex]);
            } else {
              await onClickContacts();
            }
          }
        }}
      />
    );
  };

  return (
    <div className="flex flex-col w-full">
      <div
        className={`w-full p-4 ${
          showFilter || showHistory ? "pb-1" : "pt-3"
        } pr-[18px]`}
      >
        {["inbox-widget", "contact-widget"].includes(type) && (
          <div className="w-full flex items-center justify-between">
            <CompanyProfile navbar toggleSidebar={false} widget />
            <UserProfileStatus type={type} />
          </div>
        )}
        {getToggleComponent()}
        {!["inbox", "inbox-widget"].includes(type) ? (
          <div className="flex items-center justify-end pt-3">
            <div className="flex">
              <button
                type="button"
                className="inbox-button"
                onClick={() => {
                  setShow(false);
                }}
              >
                <i className="las la-arrow-left text-[16px]" />
                <span>Voltar</span>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

ToggleSelectHeader.defaultProps = {
  notificationsBacklog: 0,
  notificationsMe: 0,
  notificationsTeams: 0,
  setShowWidgetAlert: undefined,
  setShowNoTeamsAlert: undefined,
};

export default ToggleSelectHeader;
