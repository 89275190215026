/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { templatesService } from "../services";
import { IFile } from "../shared/models/interfaces/file.interface";
import { CategoryCardItemType } from "../shared/components/Templates/CategoryCardGroup.component";
import { ExternalAppTypeTemplates } from "./externalapps.slice";

export type TemplateStepsType =
  | "category"
  | "config"
  | "header"
  | "content"
  | "footer"
  | "buttons";

export interface ITemplate {
  _id?: string;
  name?: string;
  status?:
    | "active"
    | "inactive"
    | "update"
    | "active-pending"
    | "active-high"
    | "active-medium"
    | "active-low"
    | "rejected"
    | "paused"
    | "in-review"
    | "disabled"
    | "appeal-requested"
    | "draft";
  selectedStep?: TemplateStepsType;
  selectedCategory?: CategoryCardItemType | string;
  selectedConfig?: {
    name?: string;
    displayName?: string;
    externalappType?: ExternalAppTypeTemplates;
    externalappNumber?: string;
    language?: string;
  };
  selectedHeader?: {
    type: string;
    text?: string;
    video?: IFile | string;
    image?: IFile | string;
    document?: IFile | string;
    exampleURL?: string;
  };
  selectedContent?: {
    content?: string;
    contentName?: string;
    contentExample?: string;
    vars?: {
      name: string;
      oldName?: string;
      id: number;
      example: string;
    }[];
  };
  selectedButtons?: {
    type?: string;
    text?: string;
    phone?: string;
    url?: string;
  }[];
  selectedFooter?: string;
  // ======= DEFAULT =======
  created_at?: string;
  updated_at?: string;
  deleted?: boolean;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
}

export interface IFilterTemplate {
  skip: number;
  limit: number;
  filter: {
    content: string;
    externalapp: string;
    status: string;
  };
  deleted: boolean;
  allStatus?: boolean;
}

export type Templates = ITemplate[];

interface TemplatesState extends AsyncState {
  isLoadingTemplates: boolean;
  isLoadingDropdownTemplates: boolean;
  isSendingTemplate: boolean;
  isCreatingTemplates: boolean;
  isRemoving: boolean;
  templates: ITemplate[];
  totalTemplates: number;
  selectedTemplate: ITemplate | null;
  selectedLocalTemplate: ITemplate | null;
}

const initialState: TemplatesState = {
  templates: [],
  isLoadingTemplates: false,
  isSendingTemplate: false,
  isRemoving: false,
  isLoadingDropdownTemplates: false,
  isCreatingTemplates: false,
  totalTemplates: 0,
  selectedTemplate: null,
  selectedLocalTemplate: null,
  isSuccess: false,
  isError: false,
};

export const getSearchTemplates = createAsyncThunk(
  "templates/search",
  async (filterTemplate: IFilterTemplate, thunkAPI) => {
    try {
      return await templatesService.search(filterTemplate);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getTemplates = createAsyncThunk(
//   "templates",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await templatesService.templates(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const selectTemplate = createAsyncThunk(
  "templates/selectTemplate",
  async ({ _id }: { _id?: string }, thunkAPI) => {
    try {
      return !_id ? null : await templatesService.template({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendTemplate = createAsyncThunk(
  "templates/sendTemplate",
  async ({ _id }: { _id?: string }, thunkAPI) => {
    try {
      return !_id ? null : await templatesService.send({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "templates/update",
  async (
    { _template, noToast }: { _template: ITemplate; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await templatesService.update({ _template });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const deleteTemplate = createAsyncThunk(
//   "templates/delete",
//   async (ids: string[], thunkAPI) => {
//     try {
//       return await templatesService.deleteTemplates({ ids });
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const createTemplate = createAsyncThunk(
  "templates/create",
  async (_, thunkAPI) => {
    try {
      return await templatesService.create();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    reselectTemplate(state, action: PayloadAction<ITemplate | any | null>) {
      state.selectedTemplate = action?.payload?._id
        ? action.payload
        : {
            ...action.payload,
            selectedStep: action?.payload?.step,
            selectedCategory: action?.payload?.category,
            selectedConfig: action?.payload?.config,
            selectedHeader: action?.payload?.header,
            selectedContent: action?.payload?.content,
            selectedFooter: action?.payload?.footer,
            selectedButtons: action?.payload?.buttons,
          };
      state.selectedLocalTemplate = action?.payload?._id
        ? action.payload
        : {
            ...action.payload,
            selectedStep: action?.payload?.step,
            selectedCategory: action?.payload?.category,
            selectedConfig: action?.payload?.config,
            selectedHeader: action?.payload?.header,
            selectedContent: action?.payload?.content,
            selectedFooter: action?.payload?.footer,
            selectedButtons: action?.payload?.buttons,
          };
    },
    reselectLocalTemplate(
      state,
      action: PayloadAction<ITemplate | any | null>
    ) {
      state.selectedLocalTemplate = action?.payload?._id
        ? action.payload
        : {
            ...action.payload,
            selectedStep: action?.payload?.step,
            selectedCategory: action?.payload?.category,
            selectedConfig: action?.payload?.config,
            selectedHeader: action?.payload?.header,
            selectedContent: action?.payload?.content,
            selectedFooter: action?.payload?.footer,
            selectedButtons: action?.payload?.buttons,
          };
    },
    selectTemplates(state, action: PayloadAction<ITemplate[]>) {
      state.templates = action.payload || [];
    },
    selectTotalTemplates(state, action: PayloadAction<number>) {
      state.totalTemplates = action.payload || 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchTemplates.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingTemplates = true;
        }
        state.isLoadingDropdownTemplates = true;
      })
      .addCase(getSearchTemplates.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.templates = action.payload.results || [];
        // if (state.totalTemplates <= action.payload.count)
        state.totalTemplates = action.payload.count;
        state.isLoadingTemplates = false;
        state.isLoadingDropdownTemplates = false;
      })
      .addCase(getSearchTemplates.rejected, (state) => {
        state.isError = true;
        state.templates = [];
        state.isLoadingTemplates = false;
        state.isLoadingDropdownTemplates = false;
      })
      .addCase(selectTemplate.pending, (state) => {
        state.isLoadingTemplates = true;
      })
      .addCase(selectTemplate.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action?.payload?._id) {
          state.selectedTemplate = {
            ...state.selectedTemplate,
            selectedStep: action?.payload?.step,
            selectedCategory: action?.payload?.category,
            selectedConfig: action?.payload?.config,
            selectedHeader: action?.payload?.header,
            selectedContent: action?.payload?.content,
            selectedFooter: action?.payload?.footer,
            selectedButtons: action?.payload?.buttons,
          };
          state.selectedLocalTemplate = {
            ...action.payload,
            selectedStep: action?.payload?.step,
            selectedCategory: action?.payload?.category,
            selectedConfig: action?.payload?.config,
            selectedHeader: action?.payload?.header,
            selectedContent: action?.payload?.content,
            selectedFooter: action?.payload?.footer,
            selectedButtons: action?.payload?.buttons,
          };
        }
        state.isLoadingTemplates = false;
      })
      .addCase(selectTemplate.rejected, (state) => {
        state.isError = true;
        state.selectedTemplate = null;
        state.selectedLocalTemplate = null;
        state.isLoadingTemplates = false;
      })
      .addCase(sendTemplate.pending, (state) => {
        state.isLoadingTemplates = true;
        state.isSendingTemplate = true;
      })
      .addCase(sendTemplate.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action?.payload?._id) {
          customToast({
            type: IToastType.SUCCESS,
            message: `Envio de template para revisão realizado com sucesso!`,
          });
        } else {
          customToast({
            type: IToastType.ERROR,
            message: `O envio de template para revisão não foi realizado! Tente novamente!`,
          });
        }
        state.isSendingTemplate = false;
        state.isLoadingTemplates = false;
      })
      .addCase(sendTemplate.rejected, (state) => {
        state.isError = true;
        state.isSendingTemplate = false;
        state.isLoadingTemplates = false;
        customToast({
          type: IToastType.ERROR,
          message: `O envio de template para revisão não foi realizado! Tente novamente!`,
        });
      })
      .addCase(updateTemplate.pending, (/* state */) => {
        // state.isLoadingTemplates = true;
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        if (action?.payload?._id) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingTemplates = false;
      })
      .addCase(updateTemplate.rejected, (state) => {
        state.isError = true;
        state.selectedTemplate = null;
        state.isLoadingTemplates = false;
      })
      .addCase(createTemplate.pending, (state) => {
        state.isCreatingTemplates = true;
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        if (action?.payload?._id) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Template criado com sucesso!`,
          });
          const newState = state.templates;
          newState.push(action.payload);
          state.templates = newState;
          state.selectedTemplate = action.payload;
          state.selectedLocalTemplate = action.payload;
        } else {
          state.isError = true;
          if (
            typeof action?.payload === "string" &&
            action?.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um modelo com esse nome!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
        }
        state.isCreatingTemplates = false;
      })
      .addCase(createTemplate.rejected, (state) => {
        state.isError = true;
        state.isCreatingTemplates = false;
      });
    // .addCase(deleteTemplate.pending, (state) => {
    //   state.isRemoving = true;
    // })
    // .addCase(deleteTemplate.fulfilled, (state, action) => {
    //   if (
    //     action.payload !== null &&
    //     typeof action.payload !== "undefined" &&
    //     typeof action.payload?.message !== "string"
    //   ) {
    //     state.isSuccess = true;
    //     customToast({
    //       type: IToastType.SUCCESS,
    //       message: `Remoção realizada com sucesso!`,
    //     });
    //     // } else if (
    //     //   typeof action.payload?.message === "string" &&
    //     //   action.payload.message.includes("Error on delete default office hour")
    //     // ) {
    //     //   customToast({
    //     //     type: IToastType.WARNING,
    //     //     message: "Horário definido como padrão!",
    //     //   });
    //   } else {
    //     state.isError = true;
    //     customToast({
    //       type: IToastType.ERROR,
    //       message: `Algo deu errado!`,
    //     });
    //   }
    //   state.isRemoving = false;
    // })
    // .addCase(deleteTemplate.rejected, (state) => {
    //   state.isError = true;
    //   state.isRemoving = false;
    // });
  },
});

export default templatesSlice.reducer;
export const {
  logout,
  reselectTemplate,
  reselectLocalTemplate,
  selectTemplates,
  selectTotalTemplates,
} = templatesSlice.actions;
