/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
export const removeAccents = (value: string) => {
  const accentsMap = {
    á: "a",
    à: "a",
    ã: "a",
    â: "a",
    ä: "a",
    é: "e",
    è: "e",
    ê: "e",
    ë: "e",
    í: "i",
    ì: "i",
    î: "i",
    ï: "i",
    ó: "o",
    ò: "o",
    õ: "o",
    ô: "o",
    ö: "o",
    ú: "u",
    ù: "u",
    û: "u",
    ü: "u",
    ç: "c",
    Ç: "C",
    Á: "A",
    À: "A",
    Ã: "A",
    Â: "A",
    Ä: "A",
    É: "E",
    È: "E",
    Ê: "E",
    Ë: "E",
    Í: "I",
    Ì: "I",
    Î: "I",
    Ï: "I",
    Ó: "O",
    Ò: "O",
    Õ: "O",
    Ô: "O",
    Ö: "O",
    Ú: "U",
    Ù: "U",
    Û: "U",
    Ü: "U",
  };

  return value
    .split("")
    .map((char) => (accentsMap as any)[char] || char)
    .join("");
};

export const capitalizeFirstLetter = (str: string) => {
  for (let i = 0; i < str.length; i++) {
    if (/[a-zA-Z]/.test(str[i])) {
      return str.slice(0, i) + str[i].toUpperCase() + str.slice(i + 1);
    }
  }
  return str;
};

const preserveFormats = ["_", "*", "~", "--", "```"];
const escapeUrlsText = (text: string) => {
  const urlPattern =
    /(?:https?|ftp):\/\/[^\s<]+|[\w.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/gm;
  let _text = text;
  preserveFormats.map((separator, index) => {
    _text = _text.replace(new RegExp(urlPattern), (match) => {
      const escapeTag = `escapeTag${index}`;
      const regexp = new RegExp(`\\${separator}((.*?)?)\\${separator}`, "gmi");
      return regexp.test(match)
        ? match.replace(regexp, `<${escapeTag}>$1</${escapeTag}>`)
        : match;
    });
  });
  return _text;
};
const sanitizeUrlsText = (text: string) => {
  let _text = text;
  preserveFormats.map((separator, index) => {
    const escapeTag = `escapeTag${index}`;
    const regexp = new RegExp(`<${escapeTag}>((.*?)?)</${escapeTag}>`, "gmi");
    _text = _text.replace(regexp, `${separator}$1${separator}`);
  });
  return _text;
};
const useFormatText = (text: string, commenttemplate?: boolean) => {
  const breakTag = "<br>";
  let _text = text;

  if (typeof commenttemplate !== "undefined" && commenttemplate) {
    _text = _text
      .replace(/<strong>((.*?)?)<\/strong>/gm, "*$1*")
      .replace(/<b>((.*?)?)<\/b>/gm, "*$1*")
      .replace(/<i>((.*?)?)<\/i>/gm, "_$1_")
      .replace(/<em>((.*?)?)<\/em>/gm, "_$1_")
      .replace(/<s>((.*?)?)<\/s>/gm, "~$1~")
      .replace(/<img .*?>/g, "");
  } else {
    _text = escapeUrlsText(_text)
      .replace(/\*((.*?)?)\*/gm, "<b>$1</b>")
      .replace(/_((.*?)?)_/gm, "<i>$1</i>")
      .replace(/~((.*?)?)~/gm, "<s>$1</s>")
      .replace(/```((.*?)?)```/gm, "<u>$1</u>")
      .replace(/--((.*?)?)--/gm, "<tt>$1</tt>")
      .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
  }

  _text = sanitizeUrlsText(_text);
  return _text.indexOf("externalapplink") === -1
    ? _text
        .replace(/&amp;/gm, "&")
        .replace(
          /(http[s]?:\/\/[^\s<]+)/g,
          '<a class="message-link" href="$&" target="_blank" title="$&" rel="noopener noreferrer">$&</a>'
        )
    : _text;
};

export const useFormatTemplateText = (text: string) => {
  const breakTag = "<br>";
  let _text = text;

  _text = escapeUrlsText(_text)
    .replace(/\*((.*?)?)\*/gm, "<b>$1</b>")
    .replace(/_((.*?)?)_/gm, "<i>$1</i>")
    .replace(/~((.*?)?)~/gm, "<s>$1</s>")
    .replace(/```((.*?)?)```/gm, "<u>$1</u>")
    .replace(/--((.*?)?)--/gm, "<tt>$1</tt>")
    .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);

  _text = sanitizeUrlsText(_text);
  return _text.indexOf("externalapplink") === -1
    ? _text
        .replace(/&amp;/gm, "&")
        .replace(
          /(http[s]?:\/\/[^\s<]+)/g,
          '<a class="message-link" href="$&" target="_blank" title="$&" rel="noopener noreferrer">$&</a>'
        )
    : _text;
};

const decodeHtmlEntities = (text: string): string => {
  return text
    .replace(/&aacute;/g, "á")
    .replace(/&eacute;/g, "é")
    .replace(/&iacute;/g, "í")
    .replace(/&oacute;/g, "ó")
    .replace(/&uacute;/g, "ú")
    .replace(/&atilde;/g, "ã")
    .replace(/&otilde;/g, "õ")
    .replace(/&ccedil;/g, "ç")
    .replace(/&Aacute;/g, "Á")
    .replace(/&Eacute;/g, "É")
    .replace(/&Iacute;/g, "Í")
    .replace(/&Oacute;/g, "Ó")
    .replace(/&Uacute;/g, "Ú")
    .replace(/&Atilde;/g, "Ã")
    .replace(/&Otilde;/g, "Õ")
    .replace(/&Ccedil;/g, "Ç")
    .replace(/&nbsp;/g, " ")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");
};

const formatForWhatsApp = (tempDiv: HTMLDivElement): string => {
  let text = "";
  const nodes = tempDiv.childNodes;

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const tagName = (node as any).tagName.toLowerCase();

      if (tagName === "strong") {
        text += `*${node.textContent}*`;
      } else if (tagName === "em") {
        text += `_${node.textContent}_`;
      } else {
        text += formatForWhatsApp(node as HTMLDivElement);
      }
    }
  }

  return text;
};

export const convertHtmlToText = (html: string): string => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html
    .replace(/<br>/gi, "\n")
    .replace(/<br\s*[\/]?>/gi, "\n")
    .replace(/\n\s*\n/g, "\n");
  let text = tempDiv.textContent || tempDiv.innerText || "";
  text = decodeHtmlEntities(text);
  text = formatForWhatsApp(tempDiv);
  text = text.trim();
  text = text.replace(/\n/gi, "\n\n");

  return text;
};

export default useFormatText;
