/* eslint-disable react/require-default-props */
import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import HeaderCardStep from "./HeaderCardStep.component";
import FilesArea from "../Files/FilesArea.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { reselectLocalTemplate } from "../../../slices/templates.slice";
import useTemplates from "../../../hooks/features/useTemplates";
import { ExternalAppTypeTemplates } from "../../../slices/externalapps.slice";

// export interface HeaderCardGroupProps {
//   onClick?: VoidFunction;
// }

export type HeaderCardItemType =
  | "none"
  | "text"
  | "image"
  | "video"
  | "document";

export interface HeaderCardItemProps {
  type: HeaderCardItemType;
  icon: string;
  text: string;
  onClick: VoidFunction;
}

const HeaderCardGroup = () => {
  const {
    filesImage,
    setFilesImage,
    filesVideo,
    setFilesVideo,
    // filesDocument,
    // setFilesDocument,
  } = useTemplates({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);

  const onClickSelected = (type: HeaderCardItemType) => {
    if (
      // selectedLocalTemplate?.selectedHeader?.type === type ||
      type === "none"
    ) {
      dispatch(
        reselectLocalTemplate({
          ...selectedLocalTemplate,
          selectedStep: "content",
          selectedHeader: {
            type: "none",
          },
        })
      );
      navigate("#content");
    } else {
      dispatch(
        reselectLocalTemplate({
          ...selectedLocalTemplate,
          selectedStep: "header",
          selectedHeader: {
            ...selectedLocalTemplate?.selectedHeader,
            type,
            image:
              type === "image" && filesImage.length && filesImage[0]
                ? filesImage[0]
                : undefined,
            video:
              type === "video" && filesVideo.length && filesVideo[0]
                ? filesVideo[0]
                : undefined,
            // document:
            //   type === "document" && filesDocument.length && filesDocument[0]
            //     ? filesDocument[0]
            //     : undefined,
          },
        })
      );
    }
  };

  useEffect(() => {
    const _files = filesImage || filesVideo; // || filesDocument;
    if (selectedLocalTemplate?.selectedHeader?.type && _files) {
      onClickSelected(
        selectedLocalTemplate?.selectedHeader?.type as HeaderCardItemType
      );
    }
    // }, [filesImage, filesVideo, filesDocument]);
  }, [filesImage, filesVideo]);

  const cards: HeaderCardItemProps[] = [
    {
      type: "none",
      icon: "ban",
      text: "Nenhum",
      onClick: () => {
        onClickSelected("none");
      },
    },
    {
      type: "text",
      icon: "align-left",
      text: "Texto",
      onClick: () => {
        onClickSelected("text");
      },
    },
    {
      type: "image",
      icon: "image",
      text: "Imagem",
      onClick: () => {
        onClickSelected("image");
      },
    },
    {
      type: "video",
      icon: "video",
      text: "Video",
      onClick: () => {
        onClickSelected("video");
      },
    },
  ];

  // if (
  //   selectedLocalTemplate?.selectedConfig?.externalappType ===
  //   ExternalAppTypeTemplates.WHATSAPP
  // ) {
  //   cards.push({
  //     type: "document",
  //     icon: "file",
  //     text: "Documento",
  //     onClick: () => {
  //       onClickSelected("document");
  //     },
  //   });
  // }

  return (
    <div
      id="headerCardGroup"
      className="relative w-fit p-3 items-start gap-3 rounded-4 bg-secondary"
    >
      <div className="flex gap-3">
        {cards
          .filter((_c) => ["none", "text", "image", "video"].includes(_c.type))
          .map((_card) => (
            <HeaderCardStep
              key={_card.type}
              icon={_card.icon}
              text={_card.text}
              onClick={_card.onClick}
              state={
                selectedLocalTemplate?.selectedHeader?.type === _card.type
                  ? "selected"
                  : "enabled"
              }
            />
          ))}
      </div>
      {selectedLocalTemplate?.selectedHeader?.type &&
        selectedLocalTemplate?.selectedHeader?.type !== "none" && (
          <Form className="mt-3">
            {selectedLocalTemplate?.selectedHeader?.type === "text" && (
              <>
                <Form.Input
                  className=""
                  maxLength={60}
                  label="Digite o título da sua mensagem"
                  defaultValue={selectedLocalTemplate?.selectedHeader?.text}
                  onChange={(e, { value }) => {
                    dispatch(
                      reselectLocalTemplate({
                        ...selectedLocalTemplate,
                        selectedHeader: {
                          type: selectedLocalTemplate?.selectedHeader
                            ?.type as string,
                          text: value,
                        },
                      })
                    );
                  }}
                  onBlur={() => {
                    if (
                      selectedLocalTemplate?.selectedHeader?.text &&
                      selectedLocalTemplate?.selectedHeader?.type === "text"
                    ) {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "content",
                        })
                      );
                      navigate("#content");
                    }
                  }}
                  variant="outlined"
                  placeholder="Digite um título"
                />
                <span className="leading-trim-both text-edge-cap gap-1 text-25 leading-5 fg-subtle truncate max-w-[500px]">
                  <i className="las la-info-circle" /> Máximo: 60 caracteres
                </span>
              </>
            )}
            {selectedLocalTemplate?.selectedHeader?.type === "image" && (
              <FilesArea
                single
                extraClass="max-w-[580px]"
                type="IMAGE"
                files={filesImage}
                setFiles={setFilesImage}
                title="Arquivo de imagem do cabeçalho"
              />
            )}
            {selectedLocalTemplate?.selectedHeader?.type === "video" && (
              <FilesArea
                single
                extraClass="max-w-[580px]"
                type="VIDEO"
                files={filesVideo}
                setFiles={setFilesVideo}
                title="Arquivo de vídeo do cabeçalho"
              />
            )}
            {/* {selectedLocalTemplate?.selectedHeader?.type === "document" && (
              <FilesArea
                single
                extraClass="max-w-[580px]"
                type="DOCUMENT"
                files={filesDocument}
                setFiles={setFilesDocument}
                title="Arquivo de documento do cabeçalho"
              />
            )} */}
          </Form>
        )}
    </div>
  );
};

export default HeaderCardGroup;
