import { ITemplate, IFilterTemplate } from "../slices/templates.slice";
import instances from "./instances";

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: IFilterTemplate) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (filter?.content && !_filters?.content) _filters.content = filter.content;
    if (filter?.externalapp) _filters.externalapp = filter.externalapp;
    if (filter?.status) _filters.status = filter.status;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    const response = await instances.instanceTemplates.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        // fields:
        //   "name,active,created_at,created_by,default,deleted,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const template = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceTemplates.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const send = async ({ _id }: { _id: string }) => {
  try {
    const data = {
      id: _id,
    };
    const response = await instances.instanceTemplates.post("send", data);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _template }: { _template: ITemplate }) => {
  try {
    const data = _template;
    const response = await instances.instanceTemplates.patch(
      `${_template._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

// const deleteTemplates = async ({ ids }: { ids: string[] }) => {
//   try {
//     const data = { id: ids };
//     const response = await instances.instanceTemplates.delete("", {
//       data,
//     });
//     return response.data;
//   } catch (error) {
//     // console.log(error);
//     return null;
//   }
// };

const create = async () => {
  try {
    const response = await instances.instanceTemplates.post("");
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const templatesService = {
  search,
  template,
  update,
  create,
  send,
  // deleteTemplates,
};

export default templatesService;
