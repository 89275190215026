import {
  ImportSource,
  IUser,
  RoleUser,
  SpamProps,
  UserStatus,
} from "../slices/users.slice";
import instances from "./instances";

const user = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceUsers.get(`/${_id}`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const campaigns = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceUsers.get(`/${_id}/campaigns`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const profile = async ({
  externalapp,
  defaulttemplate,
  externalappnumber,
}: {
  externalapp: string;
  defaulttemplate: string;
  externalappnumber: string;
}) => {
  try {
    const data = { externalapp, defaulttemplate, externalappnumber };
    const response = await instances.instanceUsers.patch(`/profile`, data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteUsers = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceUsers.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const spamContacts = async (spamProps: SpamProps) => {
  try {
    const { ids, spam } = spamProps;
    const data = { _id: ids, spam };
    const response = await instances.instanceUsers.patch("/spam", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _user }: { _user: IUser }) => {
  try {
    let updateUser = _user;
    if (
      typeof updateUser !== "undefined" &&
      typeof updateUser?.phone !== "undefined"
    ) {
      updateUser = {
        ...updateUser,
        phone: updateUser?.phone?.replace(/[^0-9.]/g, ""),
      };
    }
    const data = updateUser;
    const response = await instances.instanceUsers.patch(
      `/${updateUser._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const createAgent = async ({
  name,
  avatar,
  email,
  password,
  phone,
  roles,
  sendinvite,
  tenant,
}: {
  name: string;
  email: string;
  avatar?: string;
  password: string;
  phone: string;
  roles: string[];
  sendinvite: boolean;
  tenant: string;
}) => {
  try {
    const data = {
      avatar,
      active: true,
      status: UserStatus.OFFLINE,
      name,
      email,
      password,
      phone,
      roles,
      sendinvite,
      tenant,
    };
    const response = await instances.instanceUsers.post("", data);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    return response.data.errorMessage || response.data.message;
  } catch (error) {
    // console.log(error);
    return (error as any)?.response?.data?.message || null;
  }
};
const createContact = async ({
  name,
  avatar,
  email,
  phone,
  tenant,
  roles,
  widget,
  code,
}: {
  name: string;
  avatar?: string;
  email: string;
  phone: string;
  tenant: string;
  roles: string[];
  widget?: boolean;
  code?: string;
}) => {
  try {
    const data = {
      name,
      avatar,
      active: true,
      status: UserStatus.OFFLINE,
      email,
      phone,
      code,
      roles,
      widget: widget || false,
      tenant:
        tenant.length === 0
          ? instances.instanceAuth.defaults.headers.common["x-tenant-id"]
          : tenant,
    };
    const response = await instances.instanceUsers.post("/contact", data);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    return response.data.errorMessage || response.data.message;
  } catch (error) {
    return (error as any)?.response?.data?.message || null;
  }
};
const searchAgents = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
  activeList,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted?: boolean;
  allStatus?: boolean;
  activeList?: string;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus && !activeList) _filters.active = false;
    if (activeList) _filters["active-list"] = activeList === "active";
    if (deleted) _filters.deleted = true;

    _filters.roles = `${RoleUser.AGENT}`;

    const response = await instances.instanceUsers.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "name,email,active,status,phone,avatar.path,avatar.name,teams.id,teams.name,teams.active,teams.deleted,updated_at,deleted,tenant",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const searchContacts = async ({
  skip,
  limit,
  filter,
  deleted,
  spam,
  allStatus,
  historySearch,
  activeList,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted?: boolean;
  spam?: boolean;
  allStatus?: boolean;
  historySearch?: boolean;
  activeList?: string;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus && !activeList) _filters.active = false;
    if (activeList) _filters["active-list"] = activeList === "active";
    if (deleted || (typeof spam !== "undefined" && spam))
      _filters.deleted = true;
    _filters.spam = typeof spam !== "undefined" && spam;

    _filters.roles = `${RoleUser.CONTACT}`;

    const response = await instances.instanceUsers.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "name,email,active,spam,externalcode,status,phone,avatar.path,avatar.name,customers.id,customers.externalcode,customers.name,customers.active,customers.deleted,updated_at,deleted,tenant",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const getAgentsByTeamId = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceUsers.get(`/team/${_id}`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const postTeamsByUserId = async ({ _ids }: { _ids: string[] }) => {
  try {
    const data = {
      id: _ids,
    };
    const response = await instances.instanceUsers.post(`/team`, data);
    return response.data[0].teams;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const postAgentsByTeamIds = async ({ _ids }: { _ids: string[] }) => {
  try {
    const data = {
      id: _ids,
    };
    const response = await instances.instanceUsers.post(`/teamuser`, data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const getContactsByCustomerId = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceUsers.get(`/customer/${_id}`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const getManyUsers = async ({ _ids }: { _ids: string }) => {
  try {
    const response = await instances.instanceUsers.get(`/many?ids=${_ids}`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const importContacts = async ({
  file,
  source,
  updateexists,
}: {
  file: File;
  source?: ImportSource;
  updateexists?: boolean;
}) => {
  try {
    const formData = new FormData();
    if (typeof file !== "undefined") {
      formData.append("file", file);
    }
    if (typeof source !== "undefined") {
      formData.append("source", source);
    }
    if (typeof updateexists !== "undefined") {
      formData.append("updateexists", updateexists.toString());
    }
    const response = await instances.instanceUsers.post(`/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const usersService = {
  user,
  profile,
  deleteUsers,
  spamContacts,
  getAgentsByTeamId,
  getContactsByCustomerId,
  update,
  createAgent,
  createContact,
  searchAgents,
  searchContacts,
  postTeamsByUserId,
  postAgentsByTeamIds,
  campaigns,
  importContacts,
  getManyUsers,
};

export default usersService;
