/* eslint-disable no-case-declarations */
/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GridButtonOptions from "./GridButtonOptions.component";
import SearchBox from "../SearchBox.component";
import { ICustomer } from "../../../slices/customers.slice";
import { IUser, Users } from "../../../slices/users.slice";
import { ITeam } from "../../../slices/teams.slice";
import { ISettings } from "../../../slices/settings.slice";
import useOnScreen from "../../../hooks/useOnScreen";
import LoadingGridCard from "./LoadingGridCard.component";
import {
  IExternalAppAgidesk,
  IExternalAppWhatsApp,
  IExternalAppBotmaker,
  IExternalAppVtex,
} from "../../../slices/externalapps.slice";
import logoonly from "../../../assets/images/logo-only-gray.png";
import CustomCard from "../CustomCard.component";
import LoadingDots from "../LoadingDots.component";
import { IOfficehour } from "../../../slices/officehours.slice";
import { IHoliday } from "../../../slices/holidays.slice";
import { IOfflinemessage } from "../../../slices/offlinemessages.slice";
import { ISurvey } from "../../../slices/surveys.slice";
import { ICampaign } from "../../../slices/campaigns.slice";
import { ICommenttemplate } from "../../../slices/commenttemplates.slice";
import { IPermissionGroup } from "../../../slices/permissiongroups.slice";
import { ITag, TagType } from "../../../slices/tags.slice";
import LinkButton from "../Buttons/LinkButton.component";
import RelationModal from "../Modals/RelationModal.component";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";
import ViewFilter from "../Templates/ViewFilter.component";
import IntegrationCard from "../Templates/IntegrationCard.component";
import BgWhatsAppSVG from "../Templates/BgWhatsApp.svg";
import EmptyStatePNG from "../../../assets/images/template-empty-state.png";
import {
  ITemplate,
  reselectLocalTemplate,
  reselectTemplate,
} from "../../../slices/templates.slice";
import { capitalizeFirstLetter } from "../../../hooks/useFormatText";
import IntegrationsDropdown from "../Dropdowns/Integrations.dropdown.component";
import TemplateStatusDropdown from "../Dropdowns/TemplateStatus.dropdown.component";
import { useAppDispatch } from "../../../hooks/redux/hooks";
import { ActiveDropdown } from "../Dropdowns";

const Grid = ({
  keyword,
  setKeyword,
  externalapp,
  setExternalapp,
  status,
  setStatus,
  active,
  setActive,
  setSkip,
  setLimit,
  list,
  total,
  title,
  icon,
  setShowCards,
  showDeleted,
  setShowDeleted,
  showSpam,
  setShowSpam,
  setShowModal,
  hasMore,
  setHasMore,
  isLoading,
}: {
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  externalapp: string;
  setExternalapp: React.Dispatch<React.SetStateAction<string>>;
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  active: "active" | "inactive" | "";
  setActive: React.Dispatch<React.SetStateAction<"active" | "inactive" | "">>;
  // skip: number;
  list:
    | Users
    | ICustomer[]
    | ITeam[]
    | ISettings[]
    | IExternalAppWhatsApp[]
    | IExternalAppAgidesk[]
    | IExternalAppBotmaker[]
    | IExternalAppVtex[]
    | IOfficehour[]
    | IHoliday[]
    | IOfflinemessage[]
    | ISurvey[]
    | ITag[]
    | ICampaign[]
    | ICommenttemplate[]
    | IPermissionGroup[];
  total: number;
  title: string;
  icon: string;
  setShowCards: VoidFunction;
  showDeleted: boolean;
  setShowDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  showSpam: boolean;
  setShowSpam: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showImport, setShowImport] = useState(false);
  const location = useLocation();
  const [listRef] = useOnScreen({
    hasMore,
    isLoading,
    setSkip,
    grid: true,
  });

  const getType = (
    item:
      | null
      | IUser
      | ICustomer
      | ITeam
      | ISettings
      | IExternalAppWhatsApp
      | IExternalAppAgidesk
      | IExternalAppBotmaker
      | IExternalAppVtex
      | IOfficehour
      | IHoliday
      | IOfflinemessage
      | ISurvey
      | ITag
      | ICampaign
      | ICommenttemplate
      | IPermissionGroup
  ) => {
    if (item === null) {
      return <LoadingGridCard />;
    }
    let _item: any = null;
    let _teams: ITeam[] = [];

    switch (icon) {
      case "las la-user":
        _item = item as IUser;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              avatar: {
                name: _item?.avatar?.name,
                path: _item?.avatar?.path,
              },
              deleted: _item?.deleted,
              roles: _item?.roles,
              email: _item?.email,
              phone: _item?.phone,
              team:
                typeof _item?.teams !== "undefined" &&
                _item?.teams?.length > 0 &&
                typeof _item.teams[0]?.name !== "undefined"
                  ? `${_item?.teams[0]?.name}${
                      _item.teams.length > 1
                        ? ` (e mais ${_item.teams.length - 1})`
                        : ""
                    }`
                  : undefined,
            }}
            instance
            url={`/settings/user/${_item?._id}`}
          />
        );
      case "las la-address-book":
        _item = item as IUser;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              avatar: {
                name: _item?.avatar?.name,
                path: _item?.avatar?.path,
              },
              customer:
                typeof _item?.customers !== "undefined" &&
                _item?.customers?.length > 0 &&
                typeof _item.customers[0]?.name !== "undefined"
                  ? `${_item?.customers[0]?.name}${
                      _item.customers.length > 1
                        ? ` (e mais ${_item.customers.length - 1})`
                        : ""
                    }`
                  : undefined,
              deleted: _item?.deleted,
              roles: _item?.roles,
              email: _item?.email,
              phone: _item?.phone,
            }}
            instance
            url={`/settings/contact/${item?._id}`}
          />
        );
      case "las la-building":
        _item = item as ICustomer;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/customer/${_item?._id}`}
          />
        );
      case "las la-user-friends":
        _item = item as ITeam;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/team/${_item?._id}`}
          />
        );
      case "lab la-whatsapp":
        _item = item as IExternalAppWhatsApp;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.fields?.name,
              active: _item?.active,
              phone: _item?.fields?.displayphone,
              deleted: _item?.deleted,
              team:
                typeof _item?.fields?.defaultteam !== "undefined" &&
                _item?.fields?.defaultteam?.length > 0 &&
                typeof _item.fields?.defaultteam[0]?.name !== "undefined"
                  ? `${_item?.fields?.defaultteam[0]?.name}${
                      _item.fields?.defaultteam.length > 1
                        ? ` (e mais ${_item.fields.defaultteam.length - 1})`
                        : ""
                    }`
                  : undefined,
            }}
            instance
            url={`/settings/integration/whatsapp/${_item?._id}`}
          />
        );
      case "agidesk":
        _item = item as IExternalAppAgidesk;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.fields?.name,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/integration/agidesk/${_item?._id}`}
          />
        );
      case "las la-robot":
        _item = item as IExternalAppBotmaker;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.fields?.name,
              active: _item?.active,
              deleted: _item?.deleted,
              team:
                typeof _item?.fields?.defaultteam !== "undefined" &&
                _item?.fields?.defaultteam?.length > 0 &&
                typeof _item.fields?.defaultteam[0]?.name !== "undefined"
                  ? `${_item?.fields?.defaultteam[0]?.name}${
                      _item.fields?.defaultteam.length > 1
                        ? ` (e mais ${_item.fields.defaultteam.length - 1})`
                        : ""
                    }`
                  : undefined,
              // phone: _item?.fields?.displayphone,
            }}
            instance
            url={`/settings/integration/agibot/${_item?._id}`}
          />
        );
      case "las la-shopping-cart":
        _item = item as IExternalAppVtex;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.fields?.name,
              active: _item?.active,
              deleted: _item?.deleted,
              // phone: _item?.fields?.displayphone,
            }}
            instance
            url={`/settings/integration/vtex/${_item?._id}`}
          />
        );
      case "las la-calendar-check":
        _item = item as IOfficehour;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              team:
                typeof _item?.teams !== "undefined" &&
                _item?.teams?.length > 0 &&
                typeof _item.teams[0]?.name !== "undefined"
                  ? `${_item?.teams[0]?.name}${
                      _item.teams.length > 1
                        ? ` (e mais ${_item.teams.length - 1})`
                        : ""
                    }`
                  : undefined,
              default: _item?.default,
            }}
            instance
            url={`/settings/officehour/${_item?._id}`}
          />
        );
      case "las la-calendar-times":
        _item = item as IHoliday;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              team:
                typeof _item?.teams !== "undefined" &&
                _item?.teams?.length > 0 &&
                typeof _item.teams[0]?.name !== "undefined"
                  ? `${_item?.teams[0]?.name}${
                      _item.teams.length > 1
                        ? ` (e mais ${_item.teams.length - 1})`
                        : ""
                    }`
                  : undefined,
            }}
            instance
            url={`/settings/holiday/${_item?._id}`}
            // setShowModal={setShowModal}
          />
        );
      case "las la-tags":
      case "las la-user-tag":
        _item = item as ITag;
        _teams = _item.teams as ITeam[];
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              team:
                typeof _teams !== "undefined" &&
                _teams?.length > 0 &&
                typeof _teams[0]?.name !== "undefined"
                  ? `${_teams[0]?.name}${
                      _teams.length > 1 ? ` (e mais ${_teams.length - 1})` : ""
                    }`
                  : undefined,
            }}
            instance
            url={
              _item.type === TagType.CHAT
                ? `/settings/tags/chattag/${_item?._id}`
                : `/settings/tags/contacttag/${_item?._id}`
            }
            // setShowModal={setShowModal}
          />
        );
      case "las la-hourglass-end":
        _item = item as IOfflinemessage;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/offlinemessage/${_item?._id}`}
            // setShowModal={setShowModal}
          />
        );
      case "las la-smile":
        _item = item as ISurvey;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              default: _item?.default,
            }}
            instance
            url={`/settings/survey/${_item?._id}`}
          />
        );
      case "las la-bullhorn":
        _item = item as ICampaign;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/campaign/${_item?._id}`}
          />
        );
      case "las la-comment":
        _item = item as ICommenttemplate;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/commenttemplate/${_item?._id}`}
          />
        );
      case "las la-envelope-square":
        const categoryLabel: { [key: string]: string } = {
          marketing: "Marketing",
          auth: "Autenticação",
          utility: "Utilidade",
        };

        _item = item as ITemplate;
        const _name = _item?.config?.displayName ?? _item?.name ?? "(Sem nome)";
        const _type = _item?.config?.externalappType ?? _item?.externalapp_type;
        const _phone =
          _item?.config?.externalappNumber?.split("|%@g1%|")?.[1] ??
          _item?.phone;

        return (
          <IntegrationCard
            badge={{ state: _item?.status?.toLowerCase() }}
            title={capitalizeFirstLetter(_name?.replace(/_/g, " "))}
            type={_type?.toLocaleLowerCase() ?? undefined}
            variant="templates"
            phone={_phone ?? "-"}
            description={categoryLabel[_item?.category as string] ?? "-"}
            onClick={() => {
              if (
                // _type?.toLocaleLowerCase() === "agibot" &&
                _item?.status?.toLowerCase() !== "draft"
              ) {
                dispatch(reselectTemplate(_item));
                dispatch(reselectLocalTemplate(_item));
                navigate(
                  `/settings/template/${
                    _type?.toLocaleLowerCase() === "whatsapp"
                      ? "whatsapp"
                      : "agibot"
                  }`
                );
              }
              // else if (
              //   _type?.toLocaleLowerCase() === "whatsapp" &&
              //   _item?.status?.toLowerCase() !== "draft"
              // ) {
              //   navigate(`/settings/template/whatsapp`);
              // }
              else {
                navigate(`/settings/template/${_item?._id}`);
              }
            }}
          />
        );
      case "las la-user-shield":
        _item = item as IPermissionGroup;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              default: _item?.default,
            }}
            instance
            url={`/settings/permissiongroup/${_item?._id}`}
          />
        );
      default:
        return <LoadingGridCard />;
    }
  };

  const getTotalInfo = () => {
    if (isLoading) {
      return <p className="text-lg">Buscando resultados...</p>;
    }
    if (!isLoading && total > 0) {
      return (
        <p className="text-lg">
          <b>{total}</b> resultado(s)
        </p>
      );
    }
    return null;
  };

  const getEmptyState = () => {
    switch (icon) {
      case "las la-envelope-square":
        return (
          <div className="relative flex flex-col items-center justify-center">
            <BgWhatsAppSVG />
            <img
              alt="empty-state"
              src={EmptyStatePNG}
              className="absolute top-10 left-1/2 transform -translate-x-1/2"
            />
            <div className="flex flex-col items-center gap-6">
              <h1 className="fg-default text-250 leading-8 font-bold text-center leading-trim-both text-edge-cap">
                {keyword || externalapp || status
                  ? "Nenhum template encontrado"
                  : "Nenhum template criado ainda"}
              </h1>
              {keyword || externalapp || status ? (
                <span className="fg-default text-100 leading-5 text-center leading-trim-both text-edge-cap">
                  Remova seus filtros e tente novamente
                </span>
              ) : (
                <span className="fg-default text-100 leading-5 text-center leading-trim-both text-edge-cap">
                  Clique no botão{" "}
                  <span className="fg-primary text-100 leading-5 text-center leading-trim-both text-edge-cap">
                    Criar
                  </span>{" "}
                  acima para começar
                </span>
              )}
            </div>
          </div>
        );
      default:
        return "Nenhum resultado";
    }
  };

  const _instances = [
    "/settings/contacts",
    "/settings/users",
    "/settings/customers",
    "/settings/teams",
    "/settings/permissiongroups",
    "/settings/officehours",
    "/settings/holidays",
    "/settings/offlinemessages",
    "/settings/surveys",
    "/settings/tags/chattags",
    "/settings/tags/contacttags",
  ];

  return (
    <div className="container mx-auto p-0 gap-8 flex flex-col sm:py-4">
      {!_instances.includes(location.pathname) && (
        <div className="text-2xl font-medium items-center flex">
          {icon !== "agidesk" ? (
            <i className={`${icon} mr-4 text-3xl`} />
          ) : (
            <img className="mr-4 agidesk-logo" alt="Agidesk" src={logoonly} />
          )}
          <p>{title}</p>
        </div>
      )}

      <div>
        <div className="flex flex-col">
          <div className="flex flex-col xl:flex-row justify-between xl:items-center gap-2">
            <ViewFilter state="grid" onClick={setShowCards} />
            <div className="flex flex-col xl:flex-row xl:items-center gap-2">
              {location.pathname === "/settings/templates" && (
                <div className="flex flex-col xl:flex-row gap-2 xl:items-center">
                  <IntegrationsDropdown
                    setExternalapp={setExternalapp}
                    externalapp={externalapp}
                  />
                  <TemplateStatusDropdown
                    setStatus={setStatus}
                    status={status}
                  />
                </div>
              )}
              {[
                "/settings/contacts",
                "/settings/users",
                "/settings/teams",
                "/settings/customers",
                "/settings/permissiongroups",
              ].includes(location.pathname) && (
                <ActiveDropdown
                  id="dropdown-integrations"
                  noLabel
                  className={`${active ? "selected" : ""}`}
                  listType
                  value={active}
                  onChange={(e, { value }) => {
                    setSkip(0);
                    setLimit(30);
                    if (
                      typeof value === "string" &&
                      ["", "active", "inactive"].includes(value)
                    ) {
                      setActive(value as "" | "active" | "inactive");
                    }
                    if (value === "active" || value === "inactive") {
                      setShowDeleted(false);
                    }
                  }}
                />
              )}
              <SearchBox
                userPage
                placeholder={
                  location.pathname === "/settings/templates"
                    ? "Pesquisar pelo nome"
                    : undefined
                }
                _escFunction={() => {
                  setSkip(0);
                  setLimit(30);
                  setKeyword("");
                }}
                keyword={keyword}
                listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSkip(0);
                  setLimit(30);
                  setKeyword(e.target.value);
                }}
              />
            </div>
            <GridButtonOptions
              setShowModal={setShowModal}
              showDeleted={showDeleted}
              setShowDeleted={setShowDeleted}
              showSpam={showSpam}
              setShowSpam={setShowSpam}
              setLimit={setLimit}
              setSkip={setSkip}
              setActive={setActive}
            />
          </div>
        </div>
        <div className="flex items-center justify-between mt-2">
          {getTotalInfo()}
          {(isAdmin || isSupervisor) &&
          location.pathname === "/settings/contacts" ? (
            <LinkButton
              extraClass={`!flex-1 !justify-end ${
                getTotalInfo() === null ? "mt-[2px]" : ""
              }`}
              // color
              leftIcon="las la-upload mr-1"
              label="Importar"
              onClick={() => {
                setShowImport(true);
              }}
            />
          ) : null}
        </div>
      </div>

      {/* {isLoading && total === 0 && list.length === 0 ? ( */}
      {isLoading ? (
        <LoadingDots
          className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
          large
        />
      ) : (
        <ul className="flex flex-wrap -mx-1 lg:-mx-4">
          {list.length > 0 ? (
            <>
              {list.map(
                (
                  item:
                    | IUser
                    | ICustomer
                    | ITeam
                    | ISettings
                    | IExternalAppWhatsApp
                    | IExternalAppAgidesk
                    | IExternalAppBotmaker
                    | IExternalAppVtex
                    | IOfficehour
                    | IHoliday
                    | IOfflinemessage
                    | ISurvey
                    | ITag
                    | ICampaign,
                  index
                ) => (
                  <li
                    ref={index === list.length - 3 ? listRef : null}
                    key={item?._id || index}
                    className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3"
                  >
                    {getType(item)}
                  </li>
                )
              )}
              {isLoading ? (
                <li
                  key="loading"
                  className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3"
                >
                  {getType(null)}
                </li>
              ) : null}
            </>
          ) : (
            <div className="flex w-full h-full justify-center p-6">
              {getEmptyState()}
            </div>
          )}
        </ul>
      )}
      {showImport ? (
        <RelationModal
          icon="las la-upload"
          setShowModal={setShowImport}
          title="Importar contatos"
        />
      ) : null}
    </div>
  );
};

export default Grid;
