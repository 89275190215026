import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import locales from "../utils/locales";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import PopupComponent from "../../../shared/components/Popup.component";
// import { DashcardType } from "../../../slices/dashboard.slice";

const DashPie = ({
  title,
  tooltip,
  // type,
  seriesPie,
  options,
  lastUpdatedAt
}: {
  title: string;
  tooltip: string;
  // type: DashcardType;
  seriesPie?: any;
  options?: string[];
  lastUpdatedAt?: string;
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const getWidth = () => {
    // if (width > 1024) return "550";
    if (width > 640) return "477";
    if (width > 375) return "420";
    return "235";
  };

  const getFontDetails = () => {
    // if (width > 1024) return "text-[32px]";
    if (width > 640) return "text-[28px]";
    if (width > 375) return "text-[22px]";
    return "text-[18px]";
  };

  const getFontSize = () => {
    // if (width > 1024) return "32";
    if (width > 640) return "28";
    if (width > 375) return "22";
    return "18";
  };

  const getFontDetailsLabel = () => {
    // if (width > 1024) return "text-[18px]";
    if (width > 640) return "text-[14px]";
    if (width > 375) return "text-[12px]";
    return "text-[11px]";
  };

  useEffect(() => {
    getWidth();
    getFontDetails();
    getFontDetailsLabel();
    getFontSize();
  }, [width]);

  const _options = options?.map((_option) =>
    t(`dashboards.filter.type.${_option}`)
  );

  const _series = seriesPie;

  return (
    <div className="w-full bg-white rounded-md flex flex-col items-center h-[280px] border border-gray">
      <PopupComponent
        className="flex items-center self-start text-[16px] w-[290px] sm:w-[477px] text-gray-333 p-4 font-semibold"
        label={
          <div className="flex flex-col gap-1">
            <span>{title}</span>
            {lastUpdatedAt && (<span className="text-xs text-gray-999">Última atualização: {format(new Date(lastUpdatedAt), "dd/MM/yyyy HH:mm:ss", { locale: ptBR })}</span>)}
          </div>
        }
        content={
          <div>
            <h1 className="font-semibold text-[12px]">{tooltip}</h1>
          </div>
        }
      />
      {/* <h1 className="self-start text-[16px] word-wrap-2 w-[290px] sm:w-[477px] text-gray-333 p-4 font-semibold">
        {title}
      </h1> */}
      <Chart
        options={{
          colors: ["#FB923C", "#FB7185", "#818CF8"],
          noData: {
            text: "Nenhum resultado",
            align: "center",
            verticalAlign: "middle",
            style: {
              fontSize: "14px",
            },
          },
          theme: {
            mode: "light",
            // palette: "palette1",
            monochrome: {
              enabled: false,
              shadeTo: "light",
              shadeIntensity: 0.65,
            },
          },
          dataLabels: {
            formatter(val, opts) {
              return opts.w.config.series[opts.seriesIndex];
            },
          },
          tooltip: {
            cssClass: "text-white",
            style: {
              fontSize: "12px",
              fontFamily: "600",
            },
          },
          chart: {
            foreColor: "#333333",
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
            locales,
            defaultLocale: "pt-br",
          },
          labels: _options,
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              options: {
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        }}
        series={_series}
        type="pie"
        height="240"
        width={getWidth()}
      />
    </div>
  );
};

DashPie.defaultProps = {
  seriesPie: [],
  options: [],
};

export default DashPie;
