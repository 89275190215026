import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ChatUsers from "../features/chats/components/ChatUsers.component";
import Inbox, {
  countSelectedFilters,
} from "../features/chats/components/Inbox.component";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  ChatStatus,
  getSearchChats,
  selectChat,
  selectHistoryContact,
  selectSearchChats,
  selectTotalSearchChats,
} from "../slices/chats.slice";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  ExternalAppType,
  getSearchExternalApps,
} from "../slices/externalapps.slice";
import { fetchToken } from "../firebase-config";
import { IUser, UserType } from "../slices/users.slice";
import ToggleSelectHeader from "../features/chats/components/ToggleHeader/ToggleSelectHeader.component";
import ChatHistory from "../features/chats/components/ChatHistory.component";
import Button from "../shared/components/Buttons/Button.component";
import { getSettings } from "../slices/settings.slice";
import useLGPD from "../hooks/useLGPD";
import { IFiltersDropdownInstances } from "../shared/components/@types/searchBarFilter.types";
import SearchbarFilters from "../shared/components/SearchbarFilters.component";
import { ChatPageProps } from "./@types/Chat.page.types";
import NoChatSelectedComponent from "../features/chats/components/NoChatSelected.component";
import { update, userdata } from "../slices/auth.slice";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";
import { getNotificationsAmount } from "../shared/utils/getChatNotificationsByUser";
import { resetWaitingMessages } from "../slices/messages.slice";

export const defaultFilters: IFiltersDropdownInstances = {
  campaigns: [],
  period: [],
  types: [],
  externalapps: [],
  status: [],
  teams: [],
  users: [],
  customers: [],
  contacts: [],
  content: [],
  groupchat: [],
  externalappitem: [],
  read: [],
  contacttags: [],
  tags: [],
};

const ChatPage = ({
  widgetType,
  toggleList,
  userToggleList,
  setToggleList,
  setUserToggleList,
  showHistory,
  setShowHistory,
  showFilter,
  setShowFilter,
  showUsersList,
  setShowUsersList,
}: ChatPageProps) => {
  const { canAccess } = useAccessControl();

  const { user, isWidgetAgent, isWidgetContact, isWidgetEmbedded } =
    useAppSelector((state) => state.auth);
  const { selectedChat, backlogChats, mineChats } = useAppSelector(
    (state) => state.chats
  );
  const { externalAppAgidesk } = useAppSelector((state) => state.externalapps);

  const { showCode } = useLGPD();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const [showWidgetAlert, setShowWidgetAlert] = useState<boolean>(false);
  const [showNoTeamsAlert, setShowNoTeamsAlert] = useState<boolean>(false);
  const [showNewGroupChat, setShowNewGroupChat] = useState<boolean>(false);

  const [isTokenFound, setIsTokenFound] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  const [searchFiltersSelected, setSearchFiltersSelected] =
    useState<IFiltersDropdownInstances>(
      user?.customfilters?.chat?.filters ?? defaultFilters
    );
  const [searchFiltersOptions, setSearchFiltersOptions] =
    useState<IFiltersDropdownInstances>(
      user?.customfilters?.chat?.filtersOptions ?? defaultFilters
    );
  const [skipSearch, setSkipSearch] = useState<number>(0);

  const clearStoragedFilters = ({ _instance }: { _instance?: string }) => {
    if (typeof _instance === "undefined") {
      document
        .querySelectorAll(".dropdown-filters .clear")
        .forEach((el: any) => el.click());
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      setSearchFiltersSelected(defaultFilters);
      setSearchFiltersOptions(defaultFilters);
      dispatch(selectSearchChats([]));
      dispatch(selectTotalSearchChats(0));
      // setRenderDataSearch([]);
      (async () => {
        await dispatch(
          update({
            _updateUser: {
              _id: user?._id,
              customfilters: {
                ...user?.customfilters,
                chat: {
                  ...user?.customfilters?.chat,
                  filters: defaultFilters,
                  filtersOptions: searchFiltersOptions,
                },
              },
            },
            noToast: true,
          })
        );
        await dispatch(userdata());
      })();
    } else {
      const _defaultFilters: any = defaultFilters;
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      setSearchFiltersSelected({
        ...searchFiltersSelected,
        [_instance]: _defaultFilters[_instance],
        // groupchat: [],
        // campaignchat: undefined,
      });
      setSearchFiltersOptions({
        ...searchFiltersOptions,
        [_instance]: _defaultFilters[_instance],
        // users: defaultFilters.users,
        // groupchat: [],
        // campaignchat: false,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (
        widgetType !== UserType.CONTACT &&
        !isWidgetContact &&
        widgetType !== UserType.EMBEDDED &&
        !isWidgetEmbedded
      ) {
        await dispatch(getSettings({}));
      }
      await dispatch(
        getSearchChats({
          skip: 0,
          limit: 30,
          filter: {
            status: [ChatStatus.WAIT],
            grouptype: "backlog",
          },
          deleted: false,
          code: showCode,
          userId: user?._id,
          sort: -1,
        })
      );
      await dispatch(
        getSearchExternalApps({
          filter: {
            type: ExternalAppType.AGIDESK,
            "fields.validate": true,
            active: true,
            deleted: false,
          },
        })
      );
      if (
        widgetType !== UserType.AGENT &&
        widgetType !== UserType.CONTACT &&
        widgetType !== UserType.EMBEDDED &&
        !isWidgetAgent &&
        !isWidgetContact &&
        !isWidgetEmbedded
      ) {
        if (!isTokenFound || !token) {
          await fetchToken({ setToken, setIsTokenFound });
        }
      }
    })();
  }, []);

  useEffect(() => {
    setShowFilter(false);
    setShowHistory(false);
    dispatch(selectHistoryContact(null));
    setToggleList("mine");
    // setToggleList(
    //   typeof searchFiltersSelected !== "undefined" &&
    //     countSelectedFilters({ searchFiltersSelected }) > 0
    //     ? "search"
    //     : "mine"
    // );
    setUserToggleList("users");
  }, [showUsersList]);

  useEffect(() => {
    (async () => {
      if (params._id && !selectedChat?._id && externalAppAgidesk.length === 0) {
        navigate("/chat/");
      }
    })();
  }, [params._id]);

  useEffect(() => {
    (async () => {
      const _condition =
        !!selectedChat?._id &&
        (location.pathname === "/chat" ||
          (location.pathname.length > 6 &&
            !location.pathname.includes(selectedChat._id)));
      const _embeddedCondition = location.pathname.includes("&embedded");
      const regex = /\/chat\/(.*?)&embedded/;
      const match = location.pathname.match(regex);
      if (_embeddedCondition && match && match[1]) {
        const _chatId = match[1];
        await dispatch(selectChat({ _id: _chatId }));
        navigate(`/chat/${_chatId}`);
        dispatch(resetWaitingMessages());
      } else if (_condition) {
        navigate(`/chat/${selectedChat._id}`);
      }
    })();
  }, [selectedChat?._id]);

  const getListComponent = () => {
    if (showHistory) {
      if (!canAccess(Permissions.VIEW_CHATS_HISTORY)) {
        return null;
      }
      return (
        <ChatHistory
          widgetType={UserType.NONE}
          setShowFilter={setShowFilter}
          setShow={setShowUsersList}
        />
      );
    }
    if (showUsersList)
      return (
        <>
          <ChatUsers
            showWidgetAlert={showWidgetAlert}
            setShowWidgetAlert={setShowWidgetAlert}
            showNoTeamsAlert={showNoTeamsAlert}
            setShowNoTeamsAlert={setShowNoTeamsAlert}
            showNewGroupChat={showNewGroupChat}
            setShowNewGroupChat={setShowNewGroupChat}
            widgetType={UserType.NONE}
            setShow={setShowUsersList}
            toggleList={userToggleList || "users"}
          />
          {userToggleList === "users" ? (
            <Button
              minWidth
              disabled={false}
              extraClass="absolute bottom-0 right-0 m-[16px] xl:w-1/4 w-1/3 min-w-fit"
              label="Novo grupo"
              onClick={() => setShowNewGroupChat(true)}
              icon="las la-plus"
            />
          ) : null}
        </>
      );
    if (showFilter)
      return (
        <SearchbarFilters
          searchInstanceName="chats"
          clearFilters={clearStoragedFilters}
          skipSearchResults={skipSearch}
          searchFiltersOptions={searchFiltersOptions}
          searchFiltersSelected={searchFiltersSelected}
          setSearchFiltersOptions={setSearchFiltersOptions}
          setSearchFiltersSelected={setSearchFiltersSelected}
          setShowFilter={setShowFilter}
          setToggleList={setToggleList}
          defaultFilters={defaultFilters}
        />
      );

    return (
      <>
        <Inbox
          clearFilters={clearStoragedFilters}
          searchFiltersSelected={searchFiltersSelected}
          searchFiltersOptions={searchFiltersOptions}
          widgetType={UserType.NONE}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setShow={setShowUsersList}
          toggleList={toggleList}
          setToggleList={setToggleList}
          skipSearch={skipSearch}
          setSkipSearch={setSkipSearch}
          setShowHistory={setShowHistory}
        />
        {canAccess(Permissions.CREATE_MY_CHATS) && (
          <Button
            minWidth
            disabled={false}
            extraClass="absolute bottom-0 right-0 m-[16px] xl:w-1/4 w-1/3 min-w-fit"
            label="Nova conversa"
            onClick={() => setShowUsersList(true)}
            icon="las la-plus"
          />
        )}
      </>
    );
  };

  return (
    <div className="3xl:justify-center 3xl:px-11 3xl:py-8 flex mx-auto h-full w-full bg-gray-bench-alpha30">
      {/* <!-- SIDEBAR --> */}
      <div
        className={`flex-container w-full sm:w-[500px] bg-background relative ${
          !selectedChat?._id ? "flex" : "!hidden md:!flex border-r"
        } 3xl:rounded-l-xl`}
      >
        {/* <!-- HEADER --> */}
        <div className="header">
          <ToggleSelectHeader
            widgetType={widgetType}
            setShow={setShowUsersList}
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setShowWidgetAlert={setShowWidgetAlert}
            setShowNoTeamsAlert={setShowNoTeamsAlert}
            type={showUsersList ? "contact" : "inbox"}
            // setShowNewGroupChat={setShowNewGroupChat}
            toggleList={
              showUsersList ? userToggleList || "users" : toggleList || "mine"
            }
            setToggleList={showUsersList ? setUserToggleList : setToggleList}
            notificationsBacklog={
              !showUsersList && canAccess(Permissions.VIEW_WAITING_LINE)
                ? backlogChats.reduce((accumulator, chat) => {
                    if (
                      chat?.notifications?.length &&
                      getNotificationsAmount(chat, user as IUser) > 0 &&
                      chat?.status &&
                      ![
                        ChatStatus.CANCELED,
                        ChatStatus.SUSPEND,
                        ChatStatus.FINISH,
                      ].includes(chat.status)
                    ) {
                      return accumulator + 1;
                    }
                    return accumulator;
                  }, 0)
                : 0
            }
            notificationsMe={
              !showUsersList && canAccess(Permissions.VIEW_MY_CHATS)
                ? mineChats.reduce((accumulator, chat) => {
                    if (
                      chat?.notifications?.length &&
                      getNotificationsAmount(chat, user as IUser) > 0 &&
                      chat?.status &&
                      ![ChatStatus.FINISH].includes(chat.status)
                    ) {
                      return accumulator + 1;
                    }
                    return accumulator;
                  }, 0)
                : 0
            }
            notificationsTeams={0}
          />
        </div>
        {/* <!-- CHATS/USERS/FILTER LIST --> */}
        {getListComponent()}
      </div>

      {/* <!-- CHAT --> */}
      <div
        className={`3xl:max-w-[1000px] w-full flex-col overflow-y-auto overflow-x-hidden flex 3xl:rounded-r-xl flex-1 ${
          location.pathname.includes("/chat/") && width < 768
            ? "3xl:rounded-l-xl"
            : "min-w-[500px]:rounded-l-xl rounded-l-none"
        }`}
      >
        {selectedChat?._id &&
        location.pathname.includes("/chat/") &&
        location.pathname.length > 6 ? (
          <Outlet />
        ) : (
          <NoChatSelectedComponent />
        )}
      </div>
    </div>
  );
};

export default ChatPage;
