/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux/hooks";
import {
  ExternalAppType,
  reselectExternalappBotmaker,
  updateExternalapps,
} from "../../../slices/externalapps.slice";
import Button from "../Buttons/Button.component";
import PhoneFlagInput from "../PhoneFlagInput.component";
import { handleMaskRegex } from "../../utils/handleMaskLength";
import useAccessControl from "../../../hooks/useAccessControl";
import { Permissions } from "../../../slices/permissiongroups.slice";
// import PhoneMaskInput from "../PhoneMaskInput.component";

const PhoneList = () => {
  const dispatch = useAppDispatch();
  const { selectedExternalAppBotmaker } = useAppSelector(
    (state) => state.externalapps
  );

  const { canAccess } = useAccessControl();

  const [relationValue, setRelationValue] = useState<{
    name: string;
    phone: string;
  }>({
    name: "",
    phone: "",
  });

  const [data, setData] = useState(
    selectedExternalAppBotmaker?.fields?.externalphones || []
  );

  const updateExternalAppWithRelation = async ({
    relations,
  }: {
    relations: { name: string; phone: string }[];
  }) => {
    const _externalapp = {
      ...selectedExternalAppBotmaker,
      type: ExternalAppType.BOTMAKER,
      fields: {
        ...selectedExternalAppBotmaker.fields,
        externalphones: relations,
        defaultteam: selectedExternalAppBotmaker?.fields?.defaultteam?.map(
          (_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }
        ),
      },
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    dispatch(
      reselectExternalappBotmaker({
        ...selectedExternalAppBotmaker,
        fields: {
          ...selectedExternalAppBotmaker.fields,
          externalphones: relations,
        },
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      })
    );
  };

  const handleClick = async () => {
    let exists = false;
    const _data = data.map((_relation) => {
      if (_relation?.phone === relationValue?.phone) {
        exists = true;
        return relationValue;
      }
      return _relation;
    });
    if (exists) {
      setData(_data);
    } else {
      setData([..._data, relationValue]);
    }
    setRelationValue({
      name: "",
      phone: "",
    });
    await updateExternalAppWithRelation({
      relations: exists ? _data : [..._data, relationValue],
    });
  };

  const handleDelete = async (i: number) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
    await updateExternalAppWithRelation({
      relations: deleteVal,
    });
  };

  return (
    <Form className="flex flex-col w-full">
      <label className="flex">
        Números utilizados <h2 className="text-red ml-[2px]">*</h2>
      </label>
      <div className="mt-4 sm:flex sm:!flex-row flex-col place-items-baseline gap-4 w-full">
        <Form.Input
          className="w-full"
          label="Identificador"
          readOnly={
            selectedExternalAppBotmaker?.deleted ||
            !canAccess(Permissions.UPDATE_INTEGRATIONS_AGIBOT)
          }
          required
          autoComplete="off"
          maxLength={60}
          placeholder="Insira um identificador"
          type="text"
          value={relationValue.name}
          onChange={(e) => {
            setRelationValue({
              ...relationValue,
              name: e.target.value,
            });
          }}
        />
        <PhoneFlagInput
          className="w-full md:w-full"
          key="phone"
          label="Número"
          required
          readOnly={
            selectedExternalAppBotmaker?.deleted ||
            !canAccess(Permissions.UPDATE_INTEGRATIONS_AGIBOT)
          }
          defaultValue={relationValue.phone}
          onChange={(value: string) => {
            setRelationValue({
              ...relationValue,
              phone: value,
            });
          }}
        />
      </div>
      <div className="my-2 flex justify-end">
        <Button
          minWidth
          disabled={
            selectedExternalAppBotmaker?.deleted ||
            !canAccess(Permissions.UPDATE_INTEGRATIONS_AGIBOT) ||
            !relationValue.name
          }
          icon="las la-plus"
          label="Adicionar"
          title="Adicionar relação"
          onClick={handleClick}
        />
      </div>

      <label>Números relacionados</label>
      {data.length > 0 && typeof data[0].name !== "undefined" ? (
        <div
          className={`flex flex-wrap ${data?.length ? "" : "justify-center"}`}
        >
          {data.map((val: { name: string; phone: string }, i: number) => {
            // const _team = user?.teams?.find((_t) => _t._id === val?.team);
            return (
              <div
                key={`${val.name}-${val.phone}`}
                className="cursor-pointer mr-4 flex items-center px-5 py-3 shadow rounded-md mt-1"
              >
                <h2 className="text-[12px] font-semibold">{val.name}</h2>
                <i
                  title="Relação"
                  className="flex items-center las la-link px-2"
                />
                <h2 className="text-[12px] font-semibold">
                  {handleMaskRegex(val.phone)}
                </h2>
                {!selectedExternalAppBotmaker?.deleted &&
                canAccess(Permissions.DELETE_INTEGRATIONS_AGIBOT) ? (
                  <i
                    title="Remover relação"
                    className="pl-2 flex items-center text-red las text-[16pxX] la-times-circle cursor-pointer hover:text-red-alphaC0"
                    role="presentation"
                    onClick={() => handleDelete(i)}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-5 text-center">Nenhum número indicado</div>
      )}
    </Form>
  );
};

export default PhoneList;
