import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { ExternalAppTypeTemplates } from "../../../slices/externalapps.slice";

const ExternalappTypesDropdown = ({
  id,
  className,
  disabled,
  onChange,
  defaultValue,
  width,
  fluid,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  defaultValue?: ExternalAppTypeTemplates;
  disabled?: boolean;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  // const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <Dropdown
      // ref={dropdownRef}
      id={id || "dropdown-externalapptype"}
      className={className}
      disabled={disabled || false}
      fluid={fluid}
      width={width}
      selection
      placeholder="Integração"
      defaultValue={defaultValue || ExternalAppTypeTemplates.BOTMAKER}
      onChange={onChange}
      clearable
      options={[
        {
          key: 0,
          text: "Agibot",
          value: ExternalAppTypeTemplates.BOTMAKER,
        },
        // {
        //   key: 1,
        //   text: "WhatsApp",
        //   value: ExternalAppTypeTemplates.WHATSAPP,
        // },
      ]}
    />
  );
};

ExternalappTypesDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
};

export default ExternalappTypesDropdown;
